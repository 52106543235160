import { config } from "../config";


export class pageRouteUrls
{
    public static Home(): string  {
        return  `/home`;
    }
    public static Search(): string  {
        return  `/search`;
    }
    public static Request_Add(): string {
        return  `/FOIPRequest/add`; 
    }
    public static Request_Edit(id:any): string {
        if(!id || id==0)
            return this.Request_Add();
        else
            return `/FOIPRequest/edit/${id}`; 
    }
    public static Request_Details(id:any): string {
        return `/FOIPRequest/details/${id}`; 
    } 
  
    public static Action_Add(requestId:any): string {
        return  `/FOIPRequest/${requestId}/Action/add`; 
    }
    public static Action_Edit(requestId:any,id:any): string {
        if(!id || id==0)
            return this.Action_Add(requestId);
        else
            return `/FOIPRequest/${requestId}/Action/edit/${id}`; 
    }
    public static Action_Details(requestId:any,id:any): string {
        return `/FOIPRequest/${requestId}/Action/details/${id}`; 
    }          
    public static Applicant_Add(): string {
        return  `/applicant/add`; 
    }
    public static Applicant_Edit(id:any): string {
        if(!id || id==0)
            return this.Applicant_Add();
        else
            return `/applicant/edit/${id}`; 
            
    }
    public static Applicant_Details(id:any): string {
         return `/applicant/details/${id}`; 
    }     

    public static MngExceptions(requestId:any): string {
        return  `/FOIPRequest/${requestId}/MngExceptions`; 
    }

    public static Reports(): string {
        return `/Reports`; 
    }    

    public static NotificationsConfig_View(): string {
        return  `/Notifications/Config`; 
    }  
    public static NotificationsConfig_Edit(): string {
        return  `/Notifications/Config/Edit`; 
    }
    public static ConfigureExceptions_View():string{
        return  `/ConfigureExceptions`;         
    }
    public static ConfigureExceptions_Add():string{
        return  `/ConfigureExceptions/Add`;         
    }

    
}