import { Card, Col, Form, Row, Table,Button } from "react-bootstrap"; 
import {  useMemo } from 'react';
import Layout from "../../component/Layout";
import { RequestTypeEnum, SearchCriteriaEnum, getEnumLabel } from "../../helper/Enums";
import { useEffect, useState } from "react";
import * as React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';  
import { Link  } from "react-router-dom"; 
import { pageRouteUrls } from "../../helper/PageRouteUrls";
import SearchParamModel from "../../models/SearchParamModel";  
import { ErrorMessage } from "@hookform/error-message";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; 
import SearchWrapperModel from "../../models/SearchWrapperModel"; 
import NavBarState from "../../models/NavBarState";
import { LoadingSpinner } from "../../component/LoadingSpinner";
import useSearch from "./useSearch"; 

export interface Props {}

const SearchPage: React.FC<Props> = props => {   
  const [initiated,setInitiated]=useState<boolean>(true);   
  
  const searchPageContext= useSearch(); 
  const {
      searchState: {  
          loading,  
          rows,setRows,
          searchParams, SetSearchParams
      },
      renderSearchCriteria,
      onSearchSubmit,
      StripedDataGrid,
      searchFormState, 
  } = searchPageContext;

  const persistenceState=():SearchWrapperModel=> { return { searchParams:getSearchParams() ,rows:rows };}   

  const breadcrumb: any = [
    { title: "Search" }
  ];  
  const columns: GridColDef[] = [
    { field: 'fileNumber', headerName: 'File Number',
        headerClassName: 'gridHeadTr',
        renderCell: (params: GridRenderCellParams) => (
          <strong>
            <Link to={pageRouteUrls.Request_Details(params.id) }  
              state={{
                previousPageUrls:[pageRouteUrls.Search()],
                quSearchWrapper:persistenceState()
                }} > 
              {params.value}
            </Link>
          </strong>
        ),  width: 150},
    { field: 'applicantFirstName',headerClassName: 'gridHeadTr', headerName: 'Applicant', valueGetter: (value, row) => `${row.applicant.firstName} ${row.applicant.lastName}`, width: 260 },
    { field: 'requestTypeName',headerClassName: 'gridHeadTr', headerName: 'Request Type', flex: 1 },
    { field: 'dueDate',headerClassName: 'gridHeadTr', headerName:'Due Date' ,type: 'date',valueGetter: (value) => value && new Date(value),flex: 1 },
    { field: 'dateReceived',headerClassName: 'gridHeadTr',headerName: 'Date Received',type: 'date',valueGetter: (value) => value && new Date(value),flex: 1 },
    { field: 'completedDate',headerClassName: 'gridHeadTr',headerName: 'Closed Date',type: 'date', valueGetter: (value) => value && new Date(value),flex: 1 },
    { field: 'dispositionName',headerClassName: 'gridHeadTr', headerName: 'Disposition', flex: 1.5 },
  ];
  
  const setSearchTypeChanged = (e:any) => { 
     if(e.value>=0) 
       SetSearchParams({...new SearchParamModel(),searchType: Number(e.value)});
     else 
       SetSearchParams({...new SearchParamModel(),searchType: undefined});
  };

  useEffect(() => { 
    searchFormState.reset(searchParams); 
   }, []);

  useEffect(() => {       
    let params= new SearchParamModel(); 

    params.searchType= searchParams.searchType; 
    SetSearchParams(params); 
    searchFormState.reset(searchParams)

    if(!initiated)
      setRows([]);
    setInitiated(false);
  }, [searchParams.searchType]);

  const getSearchParams = () => {
    const formData = searchFormState.getValues();   
    formData.byDateFrom=formData.byDateFrom?new Date(formData.byDateFrom):undefined;
    formData.byDateTo=formData.byDateTo?new Date(formData.byDateTo):undefined;
 
    return formData; 
  };
 
  const renderDataGrid=useMemo(()=>{
    return (
        <StripedDataGrid
                                  rows={rows}
                                  key={'id'}
                                  columns={columns}
                                  initialState={{
                                    pagination: {
                                      paginationModel: { page: 0, pageSize: 5 },
                                    }, 
                                    sorting: {
                                      sortModel: [{ field: 'fileNumber', sort: 'asc' }],
                                    },
                                  }}
                                  pageSizeOptions={[5, 10,20]}
                                  getRowClassName={(params: any) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'odd':'even'  
                                  }
                                /> 
      );
  },[rows]);
 
    return ( 
          <Layout breadcrumList={breadcrumb} stateObj={[{ NavUrl: pageRouteUrls.Search(), StateObj: persistenceState() } as NavBarState]} >
           
            <Form onSubmit={searchFormState.handleSubmit(onSearchSubmit)} noValidate autoComplete="off">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Card  className="mb-3">
                    <Card.Header>
                        <Row>
                          <Col  className="fs-5">
                            <strong className="mx-2 colorDark">Search</strong>
                          </Col> 
                          <Col className="textAlign-end">
                            
                          </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body> 
                        <div className="row mb-3">
                            <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1" >
                                <label><strong>Select your Search Criteria</strong></label>
                            </div>
                            <div className="col-sm-6" >
                                <select {...searchFormState.register("searchType", { required: 'The Request Type is required.' })}
                                  onChange={e => setSearchTypeChanged(e.target)}
                                  className="form-select required"> 
                                    <option key="SearchCriteriaEnum-1" value="">- Select - </option>
                                    { Object.keys(SearchCriteriaEnum).filter((v) => !isNaN(Number(v))).map((key,index) => 
                                    (
                                        <option key={"SearchCriteriaEnum"+key} value={Number(key)}>
                                            {getEnumLabel(SearchCriteriaEnum[Number(key)])} 
                                        </option>
                                    ))}
                                </select> 
                                <ErrorMessage errors={searchFormState.formState.errors} name="searchType" render={({ message }) => <span className="error-message" >{message}</span>} />
    
                            </div>
                            <div className="col-sm-3" >
                              <Button variant="primary" type="submit" >
                                <SearchIcon />
                                Search
                              </Button> 
                            </div> 
                        </div>
                        {renderSearchCriteria(searchParams.searchType)}
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                          <Col  className="fs-5">
                            <strong className="mx-2 colorDark">Search Results</strong>
                          </Col> 
                        </Row>
                    </Card.Header>
                    <Card.Body> 
                        {loading?
                          (
                            <LoadingSpinner />
                          ):
                          (
                            <div style={{ height: 371, width: '100%' }}> 
                              {renderDataGrid}
                            </div>  
                          )                        
                        }
                    </Card.Body>
                </Card>
              </LocalizationProvider>
            </Form>

          </Layout> 
    )
}
export default SearchPage