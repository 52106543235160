import {Col, Form, Row} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {validateDate} from "../../../../helper/DateUtilities";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs, {Dayjs} from "dayjs";
import {ErrorMessage} from "@hookform/error-message";
import React, {useContext} from "react";
import ActionEditContext from "../ActionEditContext";

const ActionDateEdit = () => {
    const actionEditContext = useContext(ActionEditContext);
    if(actionEditContext == null){throw new Error("ActionEditContext is not initialized.");}
    const {
        actionEditState: {actionType, minActionDate, maxActionDate},
        actionEditFormState: {control, formState: {errors}, setValue},
        setBringForwardMinMaxDates
    } = actionEditContext;

    const setBringForwardDateOnActionChange = (date: Dayjs | null) => {
        if (!actionType?.requiresBringForwardDate || !date) return;

        let bfDate = date.add(actionType?.bringForwardMaxDaysForward, 'day');
        setValue("bringForwardDate", bfDate.toDate());
    };

    return (
            <Form.Group as={Col} lg="6">
                <Form.Label>Action Date</Form.Label>
                <Controller
                    control={control}
                    name='actionDate'
                    rules={{
                        required: 'Action Date is required.',
                        validate: (date) => validateDate(date
                            , "Action Date"
                            , minActionDate
                            , "The Action Date cannot be prior to the FOIP Request's Date Received."
                            , maxActionDate
                            , "The Action Date cannot be later than today.")
                    }}
                    render={({field, fieldState: { error }}) => (
                        <DatePicker
                            views={['year', 'month', 'day']}
                            onChange={(date) => {
                                field.onChange(date);
                                setBringForwardMinMaxDates(date);
                                setBringForwardDateOnActionChange(date);
                            } }
                            value={field.value ? dayjs(field.value) : null}
                            minDate={ minActionDate }
                            maxDate={ maxActionDate }
                            slotProps={{
                                textField: {
                                    placeholder: 'Enter the action date',
                                    className: "form-control requiredDate",
                                }
                            }}
                        />
                    )}
                />
                <ErrorMessage errors={errors} name="actionDate" render={({message}) => <span
                    className="error-message">{message}</span>}/>

            </Form.Group>
            );
}

export default ActionDateEdit;
