 
import { AxiosError } from "axios";
import { requestUtility } from "../helper/RequestUtility";
import ApplicantModel from "../models/ApplicantModel";
import {ApplicationContextType} from "../contexts/ApplicationContext";

export class applicantService {
     
    private static prepair(data:ApplicantModel) :ApplicantModel
    {
        if(data?.dateOfBirth)    
            data.dateOfBirth=new Date( data.dateOfBirth);
        return data;
    }


    public static async GetById(id:number):Promise<ApplicantModel|undefined>{
        try{  
            if(id>0)
            {
                const { data } = await requestUtility.get(`/api/v1/Applicant/${id}`); 
                return data? this.prepair(data):data;
            }
            else
                return new ApplicantModel();
        }catch(e)
        {
            requestUtility.handleError(e);
        }
        return undefined;
    }
    public static async Save(data:ApplicantModel, applicationContext?: ApplicationContextType):Promise<ApplicantModel|undefined>{
        try{  
    
            const response  =
            data.id>0 ?
                await requestUtility.put(`/api/v1/Applicant/Update`,data):
                await requestUtility.post(`/api/v1/Applicant/Add`,data);
            
            if(response)
                return response as ApplicantModel;
        }catch(e)
        {
            requestUtility.handleError(e, applicationContext);
        }
        return undefined;
    }
    public static async Search(keyword:string|undefined):Promise<ApplicantModel[]>{
        keyword = keyword?.trim();

        try{
            if(keyword && keyword.length>=2)
            { 
                const maxRecords:number=10; 
                const url=`/api/v1/Applicant/search/${maxRecords}/${encodeURIComponent(keyword)}`;
                let { data } = await requestUtility.get(url);
                
                if(data && data.length>0)
                {
                    data.forEach((d: ApplicantModel) => {
                        d = this.prepair(d);
                    }); 
                    
                    return data as ApplicantModel[]
                }
            }
        }catch(e)
        {
            console.log(e); //on bad request (i.e. invalid characters), just log it and return empty result
        }

        return [];
    } 
  };