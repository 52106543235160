import {Button} from "react-bootstrap";
import ActionEditContext from "../ActionEditContext";
import Swal from "sweetalert2";
import {actionService} from "../../../../service/ActionService";
import {pageRouteUrls} from "../../../../helper/PageRouteUrls";
import {useContext} from "react";

const ActionDelete = () => {
    const actionEditContext = useContext(ActionEditContext);
    if(actionEditContext == null){ throw new Error("ActionEditContext is not initialized."); }

    const {
        navigate,
        actionEditState: {id,
            requestId,
            btnDisable,
            setBtnDisable,
            previousPageUrls
        }
    } = actionEditContext;

    const confirmDelete = (async () => {
        setBtnDisable(true);
        Swal.fire({
            html: "<strong>Are you sure you want to delete this action item?</strong>",
            showDenyButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (!result.isConfirmed) return;
            deleteClick();
        })
        setBtnDisable(false);
    });

    const deleteClick = (async () => {
        if (!id) return;
        const result = await actionService.Delete(parseInt(id));
        if (result !== true) return;

        Swal.fire({
            icon: 'success',
            title: 'Deleted successfully',
            showConfirmButton: false,
            timer: 1500
        }).then(() => {
            (previousPageUrls && previousPageUrls.length > 0) ?
                navigate(previousPageUrls.at(-1)!, {state: {previousPageUrls: previousPageUrls}}) :
                navigate(pageRouteUrls.Request_Details(requestId));
        });
    });

    return (
        (id && Number.parseInt(id) > 0) ?
            (<Button variant="danger" onClick={confirmDelete} disabled={btnDisable}>Delete Action</Button>)
            : (<></>)
    );
};

export default ActionDelete;
