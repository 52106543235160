import React, {createContext, Dispatch} from "react";

export type ApplicationContextType = {
    validationMessage: object | null
    setValidationMessage: Dispatch<React.SetStateAction<object | null>>
}

const ApplicationContext = createContext<ApplicationContextType | undefined>(undefined);
export default ApplicationContext


