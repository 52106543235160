import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import Layout from "../component/Layout"
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { requestService } from "../service/RequestService";
import ReportParamModel from "../models/ReportParamModel";
import { validateDate2 } from "../helper/DateUtilities";
import { ErrorMessage } from "@hookform/error-message";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {blockLessGreaterThanChar} from "../helper/ValidationUtilities";

export interface Props {}

const Reports: React.FC<Props> = props => { 
  const { control, register, handleSubmit, formState: { errors }, reset,getValues } = useForm<ReportParamModel>({ defaultValues: new ReportParamModel() });   
  const [btnDisable,setBtnDisable]=useState<boolean>(false);
    
  const onSubmit: SubmitHandler<ReportParamModel> = async (data) => {
     
    setBtnDisable(true); 
    const rqst =await requestService.GenerateReportBy(data);    
    
    setBtnDisable(false);
  }
   
  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [{ title: "Reports" }];
    return (
      
      <Layout breadcrumList={breadcrumb} >
        <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <LocalizationProvider dateAdapter={AdapterDayjs}>

            <Card  className="mb-3">
              <Card.Header>
                  <Row>
                    <Col  className="fs-5">
                      <strong className="mx-2 colorDark">Reports</strong>
                    </Col>  
                  </Row>
              </Card.Header>
              <Card.Body>
                  <Container>
                    <Row className=" mb-3">
                      <Col sm={3} lg={2}>
                        <Form.Label>Report Title</Form.Label> 
                      </Col>
                      <Col sm={8} lg={10}>
                        
                        <Form.Control type="text" {...register("ReportTitle", 
                                  {  
                                    required: 'Please enter the Report Title.',  
                                  })}
                                      onKeyDown={blockLessGreaterThanChar}
                                      className="required"
                                      placeholder="Enter the Report Title" />
                        <ErrorMessage errors={errors} name="ReportTitle" render={({ message }) => <span className="error-message" >{message}</span>}/>
                      </Col>
                    </Row> 
                    <Row className=" mb-3">
                      <Col sm={3} lg={2}> 
                        <Form.Label>Reporting Period</Form.Label> 
                      </Col>
                      
                        <Col  sm={4} lg={5}> 
                          <Row>
                            <Col sm={1}>
                              <span className="label"><Form.Label>From </Form.Label></span>
                            </Col>
                            <Col sm={11}>
                                <Controller
                                      control={control}
                                      name='ByDateFrom'
                                      rules={{
                                        required:'Please enter both From and To Dates for the report.',
                                        // validate: (date) => validateDate2(date, "Date From",undefined,undefined,getValues().ByDateTo,'The Date From cannot be after Date To field.')
                                      }}
                                      render={({ field }) => (
                                        <DatePicker  
                                            maxDate={dayjs()}
                                            views={['year', 'month', 'day']}
                                            slotProps={{ textField : { 
                                                placeholder:"Enter From Date", 
                                                className: "form-control requiredDate" 
                                            } }} 
                                            onChange={(date) =>{  
                                               field.onChange(date);
                                            }}
                                            value={field.value?dayjs(field.value):null}
                                        />
                                      )}/>  
                                      <ErrorMessage errors={errors} name="ByDateFrom" render={({ message }) => <span className="error-message" >{message}</span>} />
                            </Col>
                            
                            </Row>
                        </Col>
                        <Col  sm={4} lg={5}> 
                          <Row> 
                            <Col sm={1}>
                              <span className="label"><Form.Label>To </Form.Label></span>   
                            </Col>
                            <Col sm={11}>
                                <Controller
                                        control={control}
                                        name='ByDateTo'
                                        rules={{
                                          required:'Please enter both From and To Dates for the report.',
                                          validate: (date) => validateDate2(date, "Date From",getValues().ByDateFrom,'To date cannot be prior to the From date.',undefined,undefined)
                                        }}
                                        render={({ field }) => (
                                          <DatePicker  
                                              maxDate={dayjs()}
                                              views={['year', 'month', 'day']}
                                              slotProps={{ textField : { 
                                                  placeholder:"Enter To Date", 
                                                  className: "form-control requiredDate" 
                                              } }} 
                                              onChange={(date) => field.onChange(date)}
                                              value={field.value?dayjs(field.value):null}
                                          />
                                        )}/> 
                                <ErrorMessage errors={errors} name="ByDateTo" render={({ message }) => <span className="error-message" >{message}</span>} />
                            </Col>
                          </Row>
                        </Col>  
                    </Row> 
                    <Row  className=" mb-3"> 
                      <Col className="textAlign-end">
                        <Button variant="primary" type="submit" disabled={btnDisable}>
                          Generate Report
                        </Button>
                      </Col>
                    </Row>
                  </Container>                  
              </Card.Body>
            </Card>
          </LocalizationProvider>
        </Form>  
      </Layout>
    )
}
export default Reports