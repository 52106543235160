import {loginRequest} from "./../authConfig";
import * as App from "../index";
import {SignedOutRequestStatus} from "./Constants";
import {BrowserAuthError, InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";
import {storageMng} from "./StorageMng";

async function addAuthorizedHeader(config: any, handleInteraction: boolean): Promise<any> {
    const instance = App.msalInstance;
    const accounts = instance.getAllAccounts();

    if (!accounts[0]) return Promise.reject(SignedOutRequestStatus);

    const accessTokenRequest: any = { account: accounts[0] };

    return instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse: any) => {
            // Acquire token silent success
            const accessToken = accessTokenResponse.accessToken;
            // console.log(accessToken)
            config.headers['Authorization'] = `Bearer ${accessToken}`;
            config.headers['Content-Type'] = 'application/json';
            return Promise.resolve(config);
        })
        .catch(function (error) {
            const requiresInteractiveLogin = error instanceof InteractionRequiredAuthError
                || (error instanceof BrowserAuthError && error.errorCode == "monitor_window_timeout");

            console.log(error);

            if (!handleInteraction) return Promise.reject(error);
            if (requiresInteractiveLogin) return Promise.resolve(handleLogin());
            if (error instanceof BrowserAuthError && !requiresInteractiveLogin) return addAuthorizedHeader(config, false);

            return Promise.reject(error);
        });
};

 async function handleLogin() {
    const instance = App.msalInstance;
    storageMng.clearStorage();

    await instance.loginRedirect(loginRequest)
            .catch((error) => {
                console.log(error);
            });
};

 async function handleLogout() {
    const instance = App.msalInstance;
    storageMng.clearStorage();
    await instance.logoutRedirect();
};

export default { handleLogin, handleLogout, addAuthorizedHeader  };