import {RequestActionModel} from "../../../models/RequestActionModel";
import React, {createContext, Dispatch, useContext, useState} from "react";
import RequestActionTypeModel from "../../../models/RequestActionTypeModel";
import {Dayjs} from "dayjs";
import {UseFormReturn} from "react-hook-form";
import {NavigateFunction} from "react-router";

export type ActionEditStateType = {
    id: string | undefined
    requestId: string | undefined
    actionData: RequestActionModel
    setActionData: Dispatch<React.SetStateAction<RequestActionModel>>
    actionType: RequestActionTypeModel | undefined
    setActionType: Dispatch<React.SetStateAction<RequestActionTypeModel | undefined>>
    allActionTypes: RequestActionTypeModel[]
    setAllActionTypes: Dispatch<React.SetStateAction<RequestActionTypeModel[]>>
    minBringForwardDate: Dayjs | null
    setMinBringForwardDate: Dispatch<React.SetStateAction<Dayjs | null>>
    maxBringForwardDate: Dayjs | null
    setMaxBringForwardDate: Dispatch<React.SetStateAction<Dayjs | null>>
    minActionDate: Dayjs | undefined
    maxActionDate: Dayjs | undefined
    btnDisable: boolean
    setBtnDisable: Dispatch<React.SetStateAction<boolean>>
    loading: boolean
    setLoading: Dispatch<React.SetStateAction<boolean>>
    previousPageUrls: string[]
}

export type ActionEditType = {
    actionEditState: ActionEditStateType
    actionEditFormState: UseFormReturn<RequestActionModel>
    setActionTypeById: (actionTypeId: number) => void
    setBringForwardMinMaxDates: (actionDate: Dayjs|null) => void
    loadAllActionTypes: () => void
    loadAction: () => void
    loadRequest: () => void
    navigate: NavigateFunction
}

const ActionEditContext = createContext<ActionEditType | undefined>(undefined);
export default ActionEditContext


