export default class ApplicantModel {
    id: number=0;
    public firstName: string ='';
    public lastName: string='';
    public dateOfBirth: Date|null=null;
    public address1: string='';
    public address2: string=''; 
    public city: string='Calgary';
    public province: string='Alberta';
    public country: string='Canada';
    public postalCode: string='';
    public phoneDay: string='';
    public phoneEvening: string='';
    public phoneCell: string='';
    public phoneFax: string='';
    public emailAddress: string='';
    public contactDetails: string='';
    public IsValid():boolean{
        return true;
    }
}

