import Tooltip from "@mui/material/Tooltip";
import {grey} from "@mui/material/colors";
import React from "react";

const trimTooltip = (title: string) => {
    const maxLength = 80;
    return title.length > maxLength ? title.substring(0, maxLength).concat(" ...") : title;
}

const toolTipBackground = grey[700];

const ToolTipster = ({title, children, ...props}: any) => (
    <Tooltip
        {...props}
        title={trimTooltip(title)}
        placement="top"
        arrow
        followCursor
        componentsProps={{
            tooltip: {
                sx: {
                    fontSize: "small",
                    backgroundColor: toolTipBackground
                },
            },
            arrow: {
                sx: {
                    color: toolTipBackground
                }
            }
        }}
    >
        {children}
    </Tooltip>
);

export default ToolTipster;