import RequestModel from "./RequestModel";

export default class RequestsSummaryModel {
    totalCount:number=0;
    overdueCount:number=0;
    openCount:number=0;
    completedCount :number=0;
    carriedForwardCount: number = 0;
    abandonedCount: number = 0;
    onTrackRequestsCount: number=0;
    dueWeekRequestsCount:number=0;
    overdueRequestsCount:number=0;
    onHoldRequestsCount: number=0;
    onTrackRequests: RequestModel[]=[];
    dueWeekRequests: RequestModel[]=[];
    overdueRequests: RequestModel[]=[];
    onHoldRequests: RequestModel[]=[];
}