import { requestUtility } from "../helper/RequestUtility";
import ExceptionTypeModel from "../models/ExceptionTypeModel";
import { RequestExceptionModel } from "../models/RequestExceptionModel";
import {ApplicationContextType} from "../contexts/ApplicationContext";

 
export class ExceptionService {
    public static async ExistsByCode(code: string):Promise<boolean |undefined>{
        try{   
            const { data } = await requestUtility.get(`/api/v1/ExceptionType/ExistsByCode/${code}`);   
            return data as boolean;
                
        }catch(e)
        {
            requestUtility.handleError(e);
        }
        return undefined;
    }

    public static async GetExceptionTypes():Promise<ExceptionTypeModel[]>{
        try{   
            const { data } = await requestUtility.get(`/api/v1/ExceptionType`);   
            return data as ExceptionTypeModel[];
                
        }catch(e)
        {
            requestUtility.handleError(e);
        }
        return [];
    } 
    public static async AddExceptionType(data:ExceptionTypeModel, applicationContext?: ApplicationContextType ):Promise<ExceptionTypeModel|undefined>{
        try{  
            const response  = 
                await requestUtility.post(`/api/v1/ExceptionType/Add`,data);
            
            if(response)
                return response as ExceptionTypeModel;
        }catch(e)
        {
            requestUtility.handleError(e, applicationContext);
        }
        return undefined;
    }
    public static async DeleteExceptionType(ids:number[]):Promise<boolean|undefined>{
        try{   
            const response  = 
                await requestUtility.delete(`/api/v1/ExceptionType`,ids);
            
            if(response)
                return response as boolean;
        }catch(e)
        {
            requestUtility.handleError(e);            
        }
        return undefined;
    }
    


    public static async Add(data:RequestExceptionModel ):Promise<RequestExceptionModel|undefined>{
        try{  
            const response  = 
                await requestUtility.post(`/api/v1/RequestException/Add`,data);
            
            if(response)
                return response as RequestExceptionModel;
        }catch(e)
        {
            requestUtility.handleError(e);            
        }
        return undefined;
    }
    public static async Delete(ids:number[]):Promise<boolean|undefined>{
        try{  
            const response  =
                await requestUtility.delete(`/api/v1/RequestException`,ids);
            if(response)
                return response as boolean;
        }catch(e)
        {
            requestUtility.handleError(e);            
        }
        return undefined;
    }
    public static async Save(requestId:number,selectedExpIds:number[], applicationContext?: ApplicationContextType ):Promise<RequestExceptionModel[]>{
        try{  
            const response  = 
                await requestUtility.post(`/api/v1/RequestException/Save`,{ RequestId :requestId,ExceptionTypeIds:selectedExpIds});
            
            if(response)
                return response as RequestExceptionModel[];
        }catch(e)
        {
            requestUtility.handleError(e, applicationContext);
        }
        return [];
    }
    public static async GetRequestExceptionsByRequestId(requestId:number ):Promise<RequestExceptionModel[]>{
        try{  
            let { data } = await requestUtility.get(`/api/v1/RequestException/ByRequestId/${requestId}`);
            return data as RequestExceptionModel[];
        }catch(e)
        {
            requestUtility.handleError(e);            
        }
        return []; 
    }
    public static async GetRequestExceptionsByExceptionTypeIds(ids:number[] ):Promise<RequestExceptionModel[]>{
        try{  
            const queryString = ids.join(',');  
            let { data } = await requestUtility.get(`/api/v1/RequestException/ByExceptionTypeIds/${queryString}`);
            return data as RequestExceptionModel[];
        }catch(e)
        {
            requestUtility.handleError(e);            
        }
        return []; 
    }
     
  };