import React from "react";
import {Col, Form, Row} from "react-bootstrap";

const ExtensionRequestedDetails = ({actionData}: any) => {
    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>Extension Days</Form.Label>
                <span className="ps-4">{actionData.extensionDays} </span>
            </Form.Group>
        </Row>
    );
};

export default ExtensionRequestedDetails;
