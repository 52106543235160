import Layout from "../../component/Layout"
import {Row, Col, Card, Form, Button, Stack, Table,} from "react-bootstrap";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Checkbox} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {pageRouteUrls} from "../../helper/PageRouteUrls";
import {ExceptionService} from "../../service/ExceptionService";
import ExceptionTypeModel from "../../models/ExceptionTypeModel";
import Swal from "sweetalert2";
import {LoadingSpinner} from "../../component/LoadingSpinner";  


export interface Props {
}

const ConfigureExceptionsView: React.FC<Props> = props => {

    const [loading, setLoading] = useState<boolean>(false);
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [allExceptionTypes, setAllExceptionTypes] = useState<ExceptionTypeModel[]>([]);
    const [selectedExpTypes, setSelectedExpTypes] = useState<number[]>([]);
    
    const breadcrumb: any = []; 
    breadcrumb.push({title: `Configure Exceptions`});

    useEffect(() => { 
            const fetchData = async () => {
                setBtnDisable(true);
                setLoading(true);
                const allExceptionTypes = await ExceptionService.GetExceptionTypes();

                setAllExceptionTypes(allExceptionTypes);
                  
                setLoading(false);
                setBtnDisable(false);
            }
            fetchData(); 
    }, []);

    const selectCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const changedId = Number.parseInt(event.target.value);
        if (checked) { 
            setSelectedExpTypes([...selectedExpTypes, changedId]);
        } else {
            setSelectedExpTypes(selectedExpTypes.filter((e) => e !== changedId)); 
        }
    }
 
    const deleteClick = (async () => {
        setBtnDisable(true);
        const response = await ExceptionService.GetRequestExceptionsByExceptionTypeIds(selectedExpTypes);

        if(response && response.length>0)
        {
           Swal.fire({
            title: "One or more exceptions are attached to existing Requests and cannot be deleted.",
            // html: "<h3>One or more exceptions are attached to existing Requests and cannot be deleted.<h3>" ,
            showCancelButton: false,
            });
        }
        else
        {
            Swal.fire({
                    title: `Are you sure you want to delete the exception(s)? `,
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: `No`,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setLoading(true);

                        const response = await ExceptionService.DeleteExceptionType(selectedExpTypes);
                        if(response)
                        {
                            const allExceptionTypes = await ExceptionService.GetExceptionTypes();
                            setAllExceptionTypes(allExceptionTypes);
                        }
                        setSelectedExpTypes([]);
                        setLoading(false);
                    } 
                });
        }
        setBtnDisable(false);
        
    });

    return (
        <Layout breadcrumList={breadcrumb}>
            {loading ? <LoadingSpinner/>
                : (
                    <>
                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">Configure Exceptions</strong>
                                    </Col>
                                    <Col className="textAlign-end">
                                        <Button variant="danger" onClick={deleteClick} disabled={btnDisable || selectedExpTypes.length===0}>Delete</Button>
                                         
                                        <Link to={pageRouteUrls.ConfigureExceptions_Add()} className="btn btn-primary">Add</Link>
                                    </Col> 
                                </Row>
                            </Card.Header>
                            <Card.Body> 
                                <Row className="mb-3">
                                    <Col>
                                        <Table className="ActiveHomeTable HomeTable" bordered hover size="sm">
                                            <thead>
                                            <tr className="bg-primary">
                                                <th>Select/Unselect</th>
                                                <th>Exception Code</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(allExceptionTypes && allExceptionTypes.length > 0) ?
                                                (
                                                    allExceptionTypes.map((exceptionType: ExceptionTypeModel, index: any) => {
                                                        return (
                                                            <tr key={`exceptionRow-${index}`}>
                                                                <td>
                                                                    {<Checkbox
                                                                        onChange={selectCheckChange}
                                                                        value={exceptionType.id}
                                                                    />}
                                                                </td>
                                                                <td>{exceptionType.code}</td>
                                                                <td className="tableExceptionDescriptionCell">{exceptionType.name}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) :
                                                (
                                                    <tr>
                                                        <td colSpan={3}> No Data found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </>)}
        </Layout>
    )
}

export default ConfigureExceptionsView
