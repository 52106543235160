
import Layout from "../../component/Layout"
import {Row, Col, Card, Form, Button, Stack, Table, Spinner, Container,} from "react-bootstrap";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom"; 
import React, {useContext, useState} from "react";
import {pageRouteUrls} from "../../helper/PageRouteUrls"; 
import {ExceptionService} from "../../service/ExceptionService";
import ExceptionTypeModel from "../../models/ExceptionTypeModel";
import Swal from "sweetalert2";
import {LoadingSpinner} from "../../component/LoadingSpinner"; 
import { ErrorMessage } from "@hookform/error-message";
import { SubmitHandler, useForm } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { NotificationConfigurationService } from "../../service/NotificationConfigurationService";
import ValidationAlert from "../../component/ValidationAlert";
import ApplicationContext from "../../contexts/ApplicationContext";


export interface Props {
}

const ConfigureExceptionsAdd: React.FC<Props> = props => {

    const [loading, setLoading] = useState<boolean>(false);
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string|null>(null);
    const { control, register, handleSubmit, formState ,reset,setValue ,getValues,clearErrors } = useForm<ExceptionTypeModel>({  shouldUnregister: false,defaultValues: {} });
    const breadcrumb: any = []; 
    breadcrumb.push({title: `Configure Exceptions`, link: pageRouteUrls.ConfigureExceptions_View()} ,
                    { title: `Add Exception` } ); 
    const navigate = useNavigate();
    const applicationContext = useContext(ApplicationContext);

    
    const onSubmit: SubmitHandler<ExceptionTypeModel> = async (data) => {
        setBtnDisable(true);   
        setLoading(true);  
        setErrMsg(null);
        const isExists= await ExceptionService.ExistsByCode(data.code);

        if(isExists!=undefined && isExists==true)
        {
            setErrMsg('The Exception code already exists. Please enter a different Exception code.');
        }
        else
        {
            const responceSave = await ExceptionService.AddExceptionType(data, applicationContext);
            if (responceSave) {    
                navigate(pageRouteUrls.ConfigureExceptions_View());
            } 
            reset(data);
        }
        setLoading(false);
        setBtnDisable(false);
    }

    return (
        <Layout breadcrumList={breadcrumb}>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <LocalizationProvider dateAdapter={AdapterDayjs}> 
                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">Add Exception</strong>
                                    </Col>
                                    <Col className="textAlign-end"> 
                                        <Button variant="primary" type="submit"
                                            disabled={btnDisable}>Save</Button> 
                                        
                                        <Link to={pageRouteUrls.ConfigureExceptions_View()} className="btn btn-dark">Cancel</Link>
                                    </Col> 
                                </Row>
                            </Card.Header>
                            <Card.Body> 
                                {loading ? (
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : (
                                <Container>
                                    {errMsg?
                                        (
                                        <Row className="mb-3">
                                            <ValidationAlert message={errMsg}/>
                                        </Row>
                                        ):(<></>)
                                    }
                                    <Row className=" mb-3">
                                        <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1" >
                                            <Form.Label >Exception Code</Form.Label> 
                                        </div>
                                        <div className="col-sm-6" >
                                            <Form.Control type="text" {...register("code",
                                                {
                                                        required:'Exception Code is required.' ,
                                                        maxLength: { value: 50, message: "Exception Code cannot exceed 50 characters." }
                                                    }
                                                )}
                                                placeholder="Enter Exception Code" />

                                            <ErrorMessage errors={formState.errors} name="code" render={({ message }) => <span className="error-message" >{message}</span>}/> 
                                        </div>
                                        <div className="col-sm-3" ></div>
                                    </Row> 
                                    <Row className=" mb-3 ">
                                        <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1" >
                                            <Form.Label  >Description </Form.Label> 
                                            </div>
                                        <div className="col-sm-6" >
                                            <Form.Control type="text" {...register("name",
                                                {
                                                        required:'Exception Description is required.',
                                                        maxLength: { value: 128, message: "Exception Description cannot exceed 128 characters." }
                                                    }
                                                )} 
                                                placeholder="Enter Exception Description" />

                                            <ErrorMessage errors={formState.errors} name="name" render={({ message }) => <span className="error-message" >{message}</span>}/> 
                                        </div>
                                        <div className="col-sm-3" ></div>
                                    </Row>  
                                </Container>   
                                )} 
                            </Card.Body>
                        </Card>
                </LocalizationProvider>
            </Form>        
        </Layout>
    )
}

export default ConfigureExceptionsAdd

