import { AccountInfo } from "@azure/msal-common";
import {SignedOutSessionStorage, UserProfileLocalStorage} from "./Constants";
import  secureLocalStorage  from  "react-secure-storage";
import UserModel from "../models/UserModel";

export class storageMng {
    
  static setItem(key:string,value:string ){ 
      secureLocalStorage.setItem(key, value);
  } 
  static getItem(key:string):string | null{ 
    const value =secureLocalStorage.getItem(key);
    return value?value.toString():null;
  }
  public static remove(key:string){ 
    secureLocalStorage.removeItem(key);
  }
  
  public static storUserProfile(userData:AccountInfo|null){
    let data=JSON.stringify(userData ?? {});
    this.setItem(UserProfileLocalStorage, data);
  }
  public static getUserProfile():UserModel|null{
    const json = this.getItem(UserProfileLocalStorage);
    if(json)
    {
      const usr=JSON.parse(json) as AccountInfo;
      return new UserModel(usr);
    }
    else
      return null;
  }
  
  public static removeUserProfile(){
    this.remove(UserProfileLocalStorage);
  }

  public static storSignedOut(isSignedOut:string ){
    this.setItem(SignedOutSessionStorage, isSignedOut);
  }
  public static getSignedOut():string|null{
    return this.getItem(SignedOutSessionStorage);
  }
  public static removeSignedOut(){
    this.remove(SignedOutSessionStorage);
  }

  public static clearStorage(){ 
    this.removeUserProfile();
    this.removeSignedOut();
  }
  
};