import {createTheme} from "@mui/material/styles";
import { green } from '@mui/material/colors';

const StepperTheme = createTheme({
    components: {
        MuiStepIcon: {
            styleOverrides: {
                "root": {
                    "&.Mui-completed": {
                        "color": green[500]
                    }
                }
            }
        }
    }
});

export default StepperTheme;