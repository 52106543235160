import { requestUtility } from "../helper/RequestUtility";
import { NotificationConfigModel } from "../models/NotificationConfigModel";
import {ApplicationContextType} from "../contexts/ApplicationContext";


export class NotificationConfigurationService {

    public static async GetConfig():Promise<NotificationConfigModel>{
        try{   
            const { data } = await requestUtility.get(`/api/v1/NotificationConfiguration`);   
            return data as NotificationConfigModel;                
        }catch(e)
        {
            requestUtility.handleError(e);
        }
        return new NotificationConfigModel();
    } 
    public static async Save(data:NotificationConfigModel, applicationContext?: ApplicationContextType ):Promise<NotificationConfigModel|undefined>{
        try{
            
            const response  =
            data.id>0 ?
                await requestUtility.put(`/api/v1/NotificationConfiguration/Update`,data):
                await requestUtility.post(`/api/v1/NotificationConfiguration/Add`,data);
            
            if(response)
                return response as NotificationConfigModel;
        }catch(e)
        {
            requestUtility.handleError(e, applicationContext);
        }
        return undefined;
    }
     
  };