export const getEnumLabel=(type: string|undefined)=> {
    let label= (type)?type.replace(/([A-Z])/g, ' $1').trim():'--';
    label=label.replace('Na','N/A').replace(' And ',' and ').replace('$',' - ').replace('_','.');
    return label;
}
 
export enum AccessTypeEnum
{
    None=0,
    Administrator = 1,
    Visitor = 2,
}

export enum RequestActionTypeEnum
{
    InitialFeeRequested=1,
    InitialFeeReceived=2,
    ClarificationSent=3,
    ClarificationReceived=4,
    AcknowledgementLetterSent=5,
    SearchForRecordsSent=6,
    SearchForRecordsReceived=7,
    ProcessingFeeEstimateSent=8,
    DepositProcessingFeeReceived=9,
    ThirdPartyConsultation=10,
    ThirdPartyResponseReceived=11,
    ExtensionRequired=12,
    ExtensionRequestedFromOipc=13,    
    ExtensionReceivedFromOipc=14,
    ProcessingFeeBalanceRequested=15,
    ProcessingFeeBalanceReceived=16,
    FinalLetterReady=17,
    FinalLetterSent=18
}

export enum RequestTypeEnum
{
    General=1,
    Personal=2,
    Correction=3
}

export enum RequesterSourceTypeEnum
{    
    Media=1,
    GeneralPublic=2,
    Academia=3,
    Consultation =4
}
export enum DisclosureTypeEnum
{
    Completely=1,
    Partially=2,
    NoResponsiveRecords=3,
    Na=4
}

export enum DispositionTypeEnum
{
    Abandoned=1,
    Completely=2,
    Disregard =3,
    NotDisclose =4,
    NotDisclose_PublicRecords=5,
    NoRecords=6,
    Partially=7,
    Transfer=8,
    Withdrawn=9,

    Corrected=10,
    NotCorrected=11,
    Internal$IncorrectRequests=12
}
export enum ReleaseStatusEnum
{    
    CopiesGiven=1,
    CopiesAndExamination=2,
    Examination=3,
    Na=4
}

export enum DeliveryMethodEnum
{    
    Courier=1,
    Email=2,
    ExpressPost=3,
    Fax=4,  
    InternalMail=5,
    Na=6,
    NotPickup=7,
    Pickup=8,
    PriorityPost=9,
    RegisteredMail=10,
    RegularMail=11
}

export enum SearchCriteriaEnum 
{
    ActiveRequests=1,
    Applicant=2,
    CompletedRequests=3,
    DateRange=4,
    FileNumber=5,
    Keyword=6,
    RequestType=7
}

export enum PaymentMethodEnum
{
    Cheque = 1,
    MoneyOrder = 2,
    Cash = 3,
    Na = 4
}

export enum ReportTypeEnum 
{ 
    GenerateByReportingPeriod,
    GenerateByFiscalYear,
    GenerateByCalendarYear,
    CustomReport
}

export enum DashboardIconEnum
{
    Open=1,
    Completed=2,
    Total=3,
    Overdue=4,
    CarriedForward=5,
    Abandoned=6
}