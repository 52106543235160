import Layout from "../../component/Layout"
import {Row, Col, Card, Form, Button, Stack, Table,} from "react-bootstrap";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Checkbox} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {RequestExceptionModel} from "../../models/RequestExceptionModel";
import {pageRouteUrls} from "../../helper/PageRouteUrls";
import {storageMng} from "../../helper/StorageMng";
import {ExceptionService} from "../../service/ExceptionService";
import ExceptionTypeModel from "../../models/ExceptionTypeModel";
import Swal from "sweetalert2";
import {LoadingSpinner} from "../../component/LoadingSpinner";
import {requestService} from "../../service/RequestService";
import RequestModel from "../../models/RequestModel";
import ApplicationContext from "../../contexts/ApplicationContext";


export interface Props {
}

const MngExceptions: React.FC<Props> = props => {
    const location = useLocation();
    const {requestId} = useParams();
    const navigate = useNavigate();
    const userInfo = storageMng.getUserProfile();
    const [btnDisable, setBtnDisable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const {previousPageUrls} = (location?.state ? location.state : []) as { previousPageUrls: string[] };
    const [requestExceptions, setRequestExceptions] = useState<RequestExceptionModel[]>([]);
    const [request, setRequest] = useState<RequestModel>(new RequestModel());
    const [allExceptionTypes, setAllExceptionTypes] = useState<ExceptionTypeModel[]>([]);
    const [selectedExpIds, setSelectedExpIds] = useState<number[]>([]);

    const breadcrumb: any = [];
    if (previousPageUrls && previousPageUrls[0] == pageRouteUrls.Search()) breadcrumb.push({
        title: "Search",
        link: pageRouteUrls.Search()
    });
    if (previousPageUrls && previousPageUrls.length > 0)
        breadcrumb.push({
            title: `${previousPageUrls[previousPageUrls.length - 1] == pageRouteUrls.Request_Edit(requestId) ? 'Edit ' : ''}Request Details`,
            link: previousPageUrls[previousPageUrls.length - 1],
            state: {previousPageUrls: previousPageUrls}
        });
    breadcrumb.push({title: `Manage Exceptions`});

    const applicationContext = useContext(ApplicationContext);

    useEffect(() => {
        if (requestId) {
            const fetchData = async () => {
                setBtnDisable(true);
                setLoading(true);
                const allExceptionTypes = await ExceptionService.GetExceptionTypes();
                setAllExceptionTypes(allExceptionTypes);
                const rqstExcepts = await ExceptionService.GetRequestExceptionsByRequestId(parseInt(requestId));
                setSelectedExpIds(
                    rqstExcepts.map((exceptionType: RequestExceptionModel) => exceptionType.exceptionTypeId)
                );
                if (rqstExcepts) {
                    setRequestExceptions(rqstExcepts);
                }

                const rqst = await requestService.GetById(parseInt(requestId));
                if (rqst)
                    setRequest({...rqst});

                setLoading(false);
                setBtnDisable(false);
            }
            fetchData();
        }
    }, [requestId]);

    const saveClick = (async () => {
        setBtnDisable(true);
        setLoading(true);
        if (requestId && Number.parseInt(requestId) > 0) {

            const requestExceptions = await ExceptionService.Save(Number.parseInt(requestId), selectedExpIds, applicationContext);
            setRequestExceptions(requestExceptions);
        }
        setLoading(false);
        setBtnDisable(false);
        navigate(previousPageUrls[previousPageUrls.length - 1], {state: {previousPageUrls: previousPageUrls}});
    });

    const selectCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const changedId = Number.parseInt(event.target.value);
        let selectedType = allExceptionTypes.filter(a => a.id == changedId)[0];
        if (checked) {
            setSelectedExpIds([...selectedExpIds, changedId]);
        } else {
            Swal.fire({
                title: `Are you sure you want to unselect the exception ?`,
                html: `${selectedType.code} | ${selectedType.name}`,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: `No`,
            }).then((result) => {
                if (result.isConfirmed) {
                    setSelectedExpIds(selectedExpIds.filter((e) => e !== changedId));
                } else {
                    event.target.checked = true;
                }
            });
        }
    }

    return (
        <Layout breadcrumList={breadcrumb}>
            {loading ? <LoadingSpinner/>
                : (
                    <>
                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">Exceptions Applied</strong>
                                    </Col>
                                    <Col className="textAlign-end">
                                        {userInfo?.isAdministrator ?
                                            (
                                                <>
                                                    <Button variant="primary" onClick={saveClick}
                                                            disabled={btnDisable}>Save</Button>

                                                </>
                                            ) : (<></>)
                                        }
                                        <Link to={previousPageUrls[previousPageUrls.length - 1]}
                                              state={{previousPageUrls: previousPageUrls}}
                                              className="btn btn-dark">Cancel</Link>

                                    </Col>

                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row className="mb-3">
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="p-2">
                                            <Form.Label>File Number</Form.Label>
                                        </div>
                                        <div className="p-2">
                                            <Form.Control plaintext readOnly value={request.fileNumber}/>
                                        </div>
                                    </Stack>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Table className="ActiveHomeTable HomeTable" bordered hover size="sm">
                                            <thead>
                                            <tr className="bg-primary">
                                                <th></th>
                                                <th>Exception Code</th>
                                                <th>Description</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {(allExceptionTypes && allExceptionTypes.length > 0) ?
                                                (
                                                    allExceptionTypes.map((exceptionType: ExceptionTypeModel, index: any) => {
                                                        return (
                                                            <tr key={`exceptionRow-${index}`}>
                                                                <td>
                                                                    {<Checkbox
                                                                        onChange={selectCheckChange}
                                                                        value={exceptionType.id}
                                                                        checked={(selectedExpIds.some(a => a == exceptionType.id))}
                                                                    />}
                                                                </td>
                                                                <td>{exceptionType.code}</td>
                                                                <td className="tableExceptionDescriptionCell">{exceptionType.name}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) :
                                                (
                                                    <tr>
                                                        <td colSpan={3}> No Data found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </>)}
        </Layout>
    )
}

export default MngExceptions
