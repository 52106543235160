import { useMsal } from "@azure/msal-react";
import Layout from "../component/Layout";
import {useEffect} from "react";
import { storageMng } from "../helper/StorageMng";
import authUtility from "../helper/AuthUtility";

const Login = () => {
  const { inProgress, instance } = useMsal();

  useEffect(() => {
    if(inProgress === "logout"){
      storageMng.storSignedOut("true");
    }

    if(!storageMng.getSignedOut()) {
      authUtility.handleLogin().then();
    }
  },[] );
  if(storageMng.getSignedOut()) {
    return (
        <Layout >
          <div>
            <h1>Signed Out</h1>
            <p>You have successfully signed out.</p>
          </div>
        </Layout>
    );
  }
  else {
    return(<></>);
  }
};

export default Login;
