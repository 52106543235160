import { Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import Layout from "../../component/Layout"
import {  Link, useNavigate } from "react-router-dom";  
import { useEffect, useState } from "react";  
import { useForm, SubmitHandler, Controller } from "react-hook-form"; 
import { makeStyles, styled } from '@mui/material/styles'; 
import Switch, { SwitchProps } from '@mui/material/Switch'; 
import { ErrorMessage } from "@hookform/error-message";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { NotificationConfigModel } from "../../models/NotificationConfigModel";
import { NotificationConfigurationService } from "../../service/NotificationConfigurationService";
import { pageRouteUrls } from "../../helper/PageRouteUrls"; 

export interface Props {}
 
const ViewNotificationsConfig: React.FC<Props> = props => {   
    const [loading, setLoading] =useState<boolean>(false);
    const [configData,setConfigData]=useState<NotificationConfigModel>(new NotificationConfigModel());  
     
    useEffect(() => { 
          fetchData();  
    }, []);
    const fetchData = async () => {  
        setLoading(true);
        
        const data=await NotificationConfigurationService.GetConfig(); 
        setConfigData(data);    

        setLoading(false); 
    }  
   
    const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
            opacity: 1,
            border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
        },
        '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        },
        '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        },
    }));
        

  // BREADCRUMB FOR LAYOUT
  const breadcrumb: any = [{ title: "Configure Notifications" }];
      
    return (
      
      <Layout breadcrumList={breadcrumb} > 
          <LocalizationProvider dateAdapter={AdapterDayjs}>

            <Card  className="mb-3">
                <Card.Header>
                    <Row>
                        <Col  className="fs-5">
                        <strong className="mx-2 colorDark">Configure Notifications</strong>
                        </Col>  
                        
                        <Col className="textAlign-end">  
                            <Link to={pageRouteUrls.NotificationsConfig_Edit()} className="btn btn-primary" >Edit</Link> 
                            <Link to={pageRouteUrls.Home()} className="btn btn-dark">Cancel</Link> 
                        </Col>
                    </Row>
                </Card.Header>
            <Card.Body>
                {loading ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : (
                    <Container>
                        <Row className=" mb-3">
                            <Col sm={4} lg={4} className="">
                                <Form.Label >Turn Email Notifications On/Off</Form.Label> 
                            </Col>
                            <Col sm={8} lg={8}>
                                <Form.Control plaintext readOnly value={configData.sendNotifications?'ON':'OFF'} />  
                                
                            </Col>
                        </Row> 
                        <Row className=" mb-3 ">
                            <Col sm={4} lg={4} className="">
                                <Form.Label className="remove-margin-bottom">Recipient Email Addresses (separated by ;)</Form.Label>  
                            </Col>
                            <Col sm={8} lg={8}>
                                <Form.Control plaintext readOnly value={configData["emailAddress"]??''} />  
                            </Col>
                        </Row> 
                        <Row className=" mb-3">
                            <Col sm={4} lg={4} className="">
                                <Form.Label>Due Date Reminder Lead Time (in days)</Form.Label> 
                            </Col>
                            <Col sm={8} lg={8}> 
                                <Form.Control plaintext readOnly value={configData["dueDateNotificationDays"]??''} /> 
                            </Col>
                        </Row> 
                    </Container>   
                    )} 
              </Card.Body>
            </Card>
          </LocalizationProvider>
       
      </Layout>
    )
}
export default ViewNotificationsConfig
 