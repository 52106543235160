import React, { useEffect, useState } from "react";
import {Row,Col,Card,Form} from "react-bootstrap";
import ApplicantModel from "../../models/ApplicantModel";   
import { applicantService } from "../../service/ApplicantService";
import Layout from "../../component/Layout";
import { Link, useLocation, useParams } from "react-router-dom";
import {DateFormat} from "../../helper/Constants";
import { pageRouteUrls } from "../../helper/PageRouteUrls";
import { storageMng } from "../../helper/StorageMng";
import {LoadingSpinner} from "../../component/LoadingSpinner";
import { Format } from "../../helper/DateUtilities";

export interface Props {}

const ApplicantDetails: React.FC<Props> = props => {
  const location = useLocation();
  const [loading, setLoading] =useState<boolean>(false);
  const { sourceRequestId } =(location?.state? location.state:{sourceRequestId:0}) as { sourceRequestId: number  };
  const { previousPageUrls } = (location?.state ? location.state : []) as { previousPageUrls: string[] };
  
    // Applicant ID FROM PARAMS
    const userInfo = storageMng.getUserProfile();
    const { id } = useParams();
    const [applicant,setApplicant]=useState<ApplicantModel>(); 
    // BREADCRUMB FOR LAYOUT
    const breadcrumb: any =[];
    if(previousPageUrls && previousPageUrls[0] == pageRouteUrls.Search()) breadcrumb.push({ title: "Search", link: pageRouteUrls.Search()  });    
    if(previousPageUrls && previousPageUrls.length>0 && previousPageUrls[previousPageUrls.length-1] == pageRouteUrls.Request_Edit(sourceRequestId)) breadcrumb.push({ title:`${sourceRequestId && sourceRequestId>0?'Edit':'New'} Request` ,link:previousPageUrls[previousPageUrls.length-1],state:{ previousPageUrls: previousPageUrls } });
    if(previousPageUrls && previousPageUrls.length>0 && previousPageUrls[previousPageUrls.length-1] == pageRouteUrls.Request_Details(sourceRequestId)) breadcrumb.push({ title: `Request Details` ,link:previousPageUrls[previousPageUrls.length-1],state:{ previousPageUrls: previousPageUrls } });
    breadcrumb.push({ title: `Applicant Details` }); 
 
    useEffect(() => {  

      if(id)
      {
          const fetchData = async () => {
          setLoading(true);
          const data =await applicantService.GetById(parseInt(id)); 
          setApplicant(data!); 
          
          setLoading(false);
        } 
        fetchData();
      }
      
     }, [id]);  
    return (
      <Layout breadcrumList={breadcrumb}  >
          <Card className="mb-3">
            <Card.Header>
                <Row>
                  <Col  className="fs-5">
                    <strong className="mx-2 colorDark">Applicant Details</strong>
                  </Col>
                  <Col className="textAlign-end">
                    {userInfo?.isAdministrator ?
                    (
                      <Link to={pageRouteUrls.Applicant_Edit(id)} state={{sourceRequestId: sourceRequestId ,previousPageUrls: previousPageUrls}} className="btn btn-primary">Edit</Link>
                      ):(<></>)  
                    }
                    <Link to={previousPageUrls && previousPageUrls.length>0?
                                previousPageUrls[previousPageUrls.length-1]:pageRouteUrls.Home() }  
                          state={{quApplicantId: id ,previousPageUrls:previousPageUrls }}
                                className="btn btn-dark">Cancel</Link> 
                  </Col>
                </Row>
            </Card.Header>
            <Card.Body>
            {applicant?(
                  <>
                    <Row className="mb-3"> 
                      
                      <Form.Group as={Col} controlId="formfirstName">
                        <Form.Label>First Name</Form.Label> 
                        <Form.Control plaintext readOnly value={applicant["firstName"]} />  
                      </Form.Group>

                      <Form.Group as={Col} controlId="formlastName">
                        <Form.Label>Last Name</Form.Label>                  
                        <Form.Control plaintext readOnly value={applicant["lastName"]} />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formdateOfBirth">
                        <Form.Label>Date of birth</Form.Label>
                        <Form.Control plaintext readOnly value={Format(applicant["dateOfBirth"], DateFormat)} />
                      </Form.Group>
                    </Row>
                    
                    <Row className="mb-3"> 
                      <Col>  
                          <Card className="mb-2 same-height">
                            <Card.Header><strong>Address Information</strong></Card.Header>
                            <Card.Body> 

                                <div className="row mb-3"> 
                                    <div className="col-sm-3" > 
                                      <Form.Label sm={4}>Address1</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >
                                      <Form.Control plaintext readOnly value={applicant["address1"]} />
                                    </div> 
                                </div>
                                
                                <div className="row mb-3"> 
                                    <div className="col-sm-3" > 
                                        <Form.Label>Address2</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >
                                        <Form.Control plaintext readOnly value={applicant["address2"]} />
                                    </div> 
                                </div> 
                                <div className="row mb-3"> 
                                    <div className="col-sm-3" > 
                                      <Form.Label sm={4}>City</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >
                                      <Form.Control plaintext readOnly value={applicant["city"]} />
                                    </div> 
                                </div> 
                                   
                                    <div className="row mb-3"> 
                                      <div className="col-sm-3" > 
                                          <Form.Label>Province</Form.Label>
                                      </div>
                                      <div className="col-sm-8" >
                                          <Form.Control plaintext readOnly value={applicant["province"]} />
                                      </div> 
                                    </div>
                                    
                                <div className="row mb-3"> 
                                    <div className="col-sm-3" > 
                                        <Form.Label>Country</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >
                                      <Form.Control plaintext readOnly value={applicant["country"]} />
                                    </div> 
                                </div>
                                
                                  <div className="row mb-3"> 
                                    <div className="col-sm-3" > 
                                        <Form.Label>Postal Code</Form.Label>
                                  </div>
                                  <div className="col-sm-8" >
                                    <Form.Control plaintext readOnly value={applicant["postalCode"]} />
                                  </div>
                                </div>
                                
                            </Card.Body>
                          </Card>
                      </Col>
                      <Col>
                          <Card className="mb-2 same-height">
                            <Card.Header><strong>Contact Information</strong></Card.Header>                    
                            <Card.Body>  
                                  <div className="row mb-3"> 
                                      <div className="col-sm-3" >
                                        <Form.Label>Day Phone #</Form.Label>
                                      </div>
                                      <div className="col-sm-8" >
                                          <Form.Control plaintext readOnly value={applicant["phoneDay"]}  />
                                      </div> 
                                  </div> 
                                  <div className="row mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Evening Phone #</Form.Label>
                                    </div>
                                    <div className="col-sm-8" > 
                                        <Form.Control plaintext readOnly value={applicant["phoneEvening"]} /> 
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Cell Phone #</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >  

                                        <Form.Control plaintext readOnly value={applicant["phoneCell"]} /> 
                                        
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Fax #</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >  
                                        <Form.Control plaintext readOnly value={applicant["phoneFax"]} /> 
                                    </div>
                                  </div>
                                  <div className="row mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Email Address</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >
                                      <Form.Control plaintext readOnly value={applicant["emailAddress"]} />  
                                    </div>
                                  </div> 
                            </Card.Body>
                          </Card>
                      </Col>
                    </Row> 
                  </>
                  ):(
                  <LoadingSpinner /> 
                  ) }
            </Card.Body>
          </Card>
           
      </Layout>
    );
  }
export default ApplicantDetails