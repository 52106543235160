  
import Layout from "../../component/Layout"
import {Row,Col,Card,Form,Button,Dropdown,InputGroup,Container,Spinner, Stack, Table,} from "react-bootstrap"; 
import { Link, useLocation, useParams } from "react-router-dom"; 
import {Checkbox, Grid, Step, StepLabel, Stepper, ThemeProvider, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import RequestModel from "../../models/RequestModel";
import {DateFormat} from "../../helper/Constants"; 
import {Format, calculateLifecycle} from '../../helper/DateUtilities';
import { RequestActionTypeEnum, RequestTypeEnum, getEnumLabel } from "../../helper/Enums";
import { RequestExceptionModel } from "../../models/RequestExceptionModel";
import { pageRouteUrls } from "../../helper/PageRouteUrls";
import { requestService } from "../../service/RequestService";
import { storageMng } from "../../helper/StorageMng";
import {LoadingSpinner} from "../../component/LoadingSpinner";   
import RequestActionTypeModel from "../../models/RequestActionTypeModel";
import { actionTypeService } from "../../service/ActionTypeService";
import ShowMoreText from "react-show-more-text"; 
import SearchWrapperModel from "../../models/SearchWrapperModel";
import NavBarState from "../../models/NavBarState";
import StepperTheme from "../../styles/themes/StepperTheme";
import {encode} from "html-entities";

export interface Props {}

const FOIPRequestDetails: React.FC<Props> = props => {
    const location = useLocation();
    const { id } = useParams();
    const userInfo = storageMng.getUserProfile();
    const [loading, setLoading] =useState<boolean>(false); 
    const [request,setRequest]=useState<RequestModel>(); 
    const [steps,setSteps]=useState<string[]>(['Initiated','Active','Closed']); 
    const { previousPageUrls } = (location?.state ? location.state : {previousPageUrls:[]}) as { previousPageUrls: string[] };
    const { quSearchWrapper } = (location?.state ? location.state : {quSearchWrapper:null}) as { quSearchWrapper: SearchWrapperModel|null };
    
    const [allActionTypes, setAllActionTypes] = useState<RequestActionTypeModel[]>([]);
    
    const breadcrumb: any =[];
    if(previousPageUrls && previousPageUrls[0] == pageRouteUrls.Search()) breadcrumb.push({ title: "Search", link: pageRouteUrls.Search() ,state:{quSearchWrapper:quSearchWrapper} });
    breadcrumb.push({ title: `${id ? " Request Details" : "New Request"}` }); 
      
    const ActionTypeEnumLength = Object.keys(RequestActionTypeEnum).filter((v) => !isNaN(Number(v))).length; 
    useEffect(() => {
        const fetchLookupData = async () => {
            setLoading(true); 
            const allActionTypes = await actionTypeService.GetAll();
            setAllActionTypes(allActionTypes);

            setLoading(false);
        }
        fetchLookupData();  
    }, []);
    
    useEffect(() => {   
        if(id)
        {
            const fetchData = async () => { 
                setLoading(true);
                const rqst =await requestService.GetById(parseInt(id)); 
                if(rqst)
                {
                    setRequest({...rqst});   
                }
                setLoading(false);
            } 
            fetchData();
        }
    },[id] ); 
         
    useEffect(() => {   
        if(request)
        {
            setSteps( ['Initiated',(request.dueDate? 'Active':'On Hold'),'Closed']);
        }
    },[request?.dueDate] ); 


    const stepNumber=(rqt:RequestModel):number=>
    { 
        if (rqt.completedDate)
            return 2; //Closed
        let viewLifecycle=calculateLifecycle(rqt.dateReceived,rqt.completedDate);      
        if (viewLifecycle < 1)
            return 0; //Initiated or On hold
        else
            return 1; //Active
    }

    const renderActionRow=(index:Number,type: RequestActionTypeModel,request:RequestModel)=> {  

        if(request.requestActions && request.requestActions?.some(a=>a.requestActionTypeId == type.id) )
        {
            const action= request.requestActions.find(a=>a.requestActionTypeId==type.id) ;
              
            return (
                <div className="row mb-3">
                    <div className="col-sm-2">
                        <Checkbox readOnly checked={true}/>
                    </div>
                    <div className="col-sm-6" > 
                        <Link to={pageRouteUrls.Action_Details(id,action?.id)} 
                              state={{ previousPageUrls: [...previousPageUrls,pageRouteUrls.Request_Details(id)] }} 
                            >{type?.name}</Link>
                    </div>
                    <div className="col-sm-4" >
                        {(action?.actionDate)?(
                            <Form.Control plaintext readOnly value={Format(action.actionDate,DateFormat)} />
                        ):
                        (<></>)
                        }
                    </div>  
                </div> 
            )
        }
        else
        {
            return (
                <div className="row mb-3">
                    <div className="col-sm-2">
                        <Checkbox readOnly checked={false}/>
                    </div>
                    <div className="col-sm-6" > 
                        {type?.name}
                    </div>
                    <div className="col-sm-4" > -- </div>  
                </div>
            );
        }                                 
    } 
    const renderActionSummaryGrid=(request:RequestModel)=>{
        return(
                        <Row> 
                            <Col>  
                                <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                    { allActionTypes.slice(0,5).map((aType,index) => 
                                        <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                            { renderActionRow(index,aType,request) }
                                        </Grid>   
                                    )}  
                                </Grid> 
                            </Col> 
                            <Col> 
                                <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                    { allActionTypes.slice(5,10).map((aType,index) => 
                                        <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                            { renderActionRow(index,aType,request) }
                                        </Grid>   
                                    )} 
                                </Grid> 
                            </Col>  
                            <Col> 
                                <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                    { allActionTypes.slice(10,15).map((aType,index) => 
                                        <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                            { renderActionRow(index,aType,request) }
                                        </Grid>   
                                    )} 
                                </Grid> 
                            </Col> 
                            <Col> 
                                <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        { allActionTypes.slice(15,ActionTypeEnumLength).map((aType,index) => 
                                            
                                            <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                                { renderActionRow(index,aType,request) }
                                            </Grid>   
                                        )}  
                                </Grid> 
                            </Col>  
                        </Row>   
                        
        )
    }
    const renderExceptionsTable=(requestExceptions:RequestExceptionModel[])=> {
        return (
            <Table className="ActiveHomeTable HomeTable"  bordered hover size="sm">
                <thead>
                    <tr className="bg-primary">
                        <th>Exception Code</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {(requestExceptions && requestExceptions.length >0 )?
                    (
                        requestExceptions.map((exception: RequestExceptionModel, index: any)=>{
                            return ( 
                                <tr key={`exceptionRow-${index}`}>
                                    <td>{exception.exceptionType?.code}</td>
                                    <td className="tableExceptionDescriptionCell">{ exception.exceptionType?.name}</td>                          
                                </tr>
                            )
                        }) 
                    ):
                    (
                        <tr>
                            <td colSpan={2}> No Data found</td>                        
                        </tr>
                    )}
                </tbody>
            </Table> 
        )
    }
    const renderRequestDetails=(request:RequestModel)=>{
        return (
            <Row className="mb-3"> 
            <Col>
                <Card className="mb-2">
                    <Card.Header><strong>Request Processing Details</strong></Card.Header>
                    <Card.Body> 

                        <div className="row mb-3"> 
                            <div className="col-sm-4" > 
                                <Form.Label sm={4}>Request Summary</Form.Label>
                            </div>
                            <div className="col-sm-8" >  
                                <ShowMoreText 
                                    lines={2}
                                    more="Show more"
                                    less="Show less"
                                    className="content-css"
                                    anchorClass="show-more-less-clickable" 
                                    expanded={false}
                                    width={370}
                                    keepNewLines={true}
                                    truncatedEndingComponent={"... "&& <br/>}
                                >
                                    {encode(request.requestSummary) }
                                </ShowMoreText>
                            </div> 
                        </div>
                        
                        <div className="row mb-3"> 
                            <div className="col-sm-4" > 
                                <Form.Label>Request Details</Form.Label>
                            </div>
                            <div className="col-sm-8" >  
                                <ShowMoreText 
                                    lines={3}
                                    more="Show more"
                                    less="Show less"
                                    className="content-css"
                                    anchorClass="show-more-less-clickable" 
                                    expanded={false}
                                    width={370}
                                    keepNewLines={true}
                                    truncatedEndingComponent={"... "}
                                >
                                    {encode(request.requestText)}
                                </ShowMoreText>
                            </div> 
                        </div>
                        <div className="row mb-3"> 
                            <div className="col-sm-4" > 
                                <Form.Label>Request Type</Form.Label>
                            </div>
                            <div className="col-sm-8" >
                                <Form.Control plaintext readOnly value={request.requestTypeName} />  
                            </div> 
                        </div>
                        {(request.requestTypeId== RequestTypeEnum.General)?
                        (
                            <div className="row mb-3"> 
                                <div className="col-sm-4" > 
                                    <Form.Label>Request Source</Form.Label>
                                </div>
                                <div className="col-sm-8" >
                                    <Form.Control plaintext readOnly value={request.requesterSourceName} />
                                </div> 
                            </div>
                            ):(<></>)
                        }
                       

                        <div className="row mb-3"> 
                            <div className="col-sm-4" > 
                                <Form.Label sm={4}>Document Location</Form.Label>
                            </div>
                            <div className="col-sm-8" >  
                                <Form.Control plaintext readOnly value={request.sharepointUrl} />
                            </div> 
                        </div>
                         
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className="mb-2">
                    <Card.Header>
                        <strong>Request Response Details</strong>
                    </Card.Header>                    
                    <Card.Body>  
                        <div className="row mb-3"> 
                            <div className="col-sm-4" >
                                <Form.Label>Record Disclosed</Form.Label>
                            </div>
                            <div className="col-sm-8" >
                                <Form.Control plaintext readOnly value={request.disclosureName}  />
                            </div> 
                        </div> 
                        <div className="row mb-3">
                            <div className="col-sm-4" >
                                <Form.Label>Disposition</Form.Label>
                            </div>
                            <div className="col-sm-8" > 
                                <Form.Control plaintext readOnly value={request.dispositionName} /> 
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-4" >
                                <Form.Label>Release Status</Form.Label>
                            </div>
                            <div className="col-sm-8" >   
                                <Form.Control plaintext readOnly value={request.releaseStatusName} />  
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-4" >
                                <Form.Label>Method of Delivery</Form.Label>
                            </div>
                            <div className="col-sm-8" >
                                <Form.Control plaintext readOnly value={request.deliveryMethodName} />  
                            </div>
                        </div> 
                    </Card.Body>
                </Card>
                <Card className="mb-2">
                    <Card.Header>
                        <strong>Completed Request Details</strong>
                    </Card.Header>                    
                    <Card.Body>  
                        <div className="row mb-3"> 
                            <div className="col-sm-4" > 
                                <Form.Label> Number of Pages</Form.Label>
                            </div>
                            <div className="col-sm-8" >
                                <Form.Control plaintext readOnly value={request.numberOfPages??""} />
                            </div> 
                        </div>
                          
                        
                        <div className="row mb-3"> 
                            <div className="col-sm-4" > 
                                <Form.Label>Completed Date</Form.Label>
                            </div>
                            <div className="col-sm-8" >
                                <Form.Control plaintext readOnly value={Format(request.completedDate, DateFormat)} />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            </Row> 
        )
    }

    return (
      <Layout breadcrumList={breadcrumb} stateObj={[{NavUrl: pageRouteUrls.Search(),StateObj:quSearchWrapper} as NavBarState]}>
         { !request ? (
             <LoadingSpinner />
            ) : (
            <>
                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col  className="fs-5">
                                <strong className="mx-2 colorDark">Request Progress</strong>
                            </Col> 
                        
                            <Col className="textAlign-end"> 
                                {userInfo?.isAdministrator ?
                                (
                                <>
                                    <Link to={pageRouteUrls.Request_Edit(id)} state={{ previousPageUrls: previousPageUrls }} className="btn btn-primary">Edit</Link>
                                </>   
                                ):(<></>)      
                                }
                                <Link to={ previousPageUrls[0]==pageRouteUrls.Search()?pageRouteUrls.Search():pageRouteUrls.Home()} 
                                    state={{quSearchWrapper:quSearchWrapper}}
                                    className="btn btn-dark">Cancel</Link> 
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <ThemeProvider theme={StepperTheme}>
                                        <Stepper activeStep={stepNumber(request)}>
                                            {steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            const labelProps: {
                                                optional?: React.ReactNode;
                                            } = {};
                                            return (
                                                <Step key={`step-${label}`} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                            })}
                                        </Stepper>
                                    </ThemeProvider>
                                </Col>
                            </Row>
                            <Row className="mb-3"></Row>
                            <Row className="mb-3">  
                                <Form.Group as={Col} >
                                    <Form.Label>File Number</Form.Label>
                                    <Form.Control plaintext readOnly value={request.fileNumber} />  
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Date Received</Form.Label>                  
                                    <Form.Control plaintext readOnly value={Format(request.dateReceived, DateFormat)} />
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Due Date</Form.Label>              
                                    {(request.dueDate)?
                                    (<Form.Control plaintext readOnly value={Format(request.dueDate, DateFormat)} />):
                                    (<></> )
                                    }    
                                    
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Request Lifecycle</Form.Label>                   
                                    <Form.Control plaintext readOnly value={`${calculateLifecycle(request.dateReceived,request.completedDate)} days`} /> 
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <Form.Label>Request Status</Form.Label>                  
                                    <Form.Control plaintext readOnly value={request.requestStatus} />
                                </Form.Group>
                            </Row>
                        </Container>                  
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col  className="fs-5">
                                <strong className="mx-2 colorDark">Applicant</strong>
                            </Col>  
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Container>
                            <Row className="mb-3">
                                <Col lg="12">  
                                </Col>
                            </Row>
                            <Row className="mb-3">  
                                <Row>
                                    <Col>
                                        <Form.Label>First Name</Form.Label> 
                                        <Form.Control plaintext readOnly value={request.applicant.firstName} /> 
                                    </Col> 
                                    <Col>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control plaintext readOnly value={request.applicant.lastName} /> 
                                    </Col> 
                                    <Col> 
                                        <Form.Label>Contact Details</Form.Label>
                                        <Form.Control plaintext readOnly value={request.applicant.contactDetails} /> 
                                    </Col> 
                                </Row>
                                <Row> 
                                    <Col className="textAlign-end">
                                        <Form.Group as={Col} >
                                            {(request?.applicantId)?( 
                                                <Link to={pageRouteUrls.Applicant_Details(request.applicantId)} 
                                                    state={{sourceRequestId: id ,previousPageUrls: [...previousPageUrls,pageRouteUrls.Request_Details(id)]}}
                                                    >Click Here for more applicant details</Link>
                                            ):(<></>)}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                
                            </Row>
                        </Container>                  
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col  className="fs-5">
                                <strong className="mx-2 colorDark">Request Details</strong>
                            </Col>  
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {renderRequestDetails(request)} 
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col  className="fs-5">
                                <strong className="mx-2 colorDark">Action Summary</strong>
                            </Col>   
                            {request.id > 0 && (userInfo?.isAdministrator)?
                                        (
                                            <Col className="textAlign-end">
                                                <Link to={pageRouteUrls.Action_Add(id)} 
                                                    state={{ previousPageUrls: [...previousPageUrls,pageRouteUrls.Request_Details(id)] }} 
                                                    className="btn btn-success">New Action</Link>
                                            </Col>
                                        ) : (
                                            <></>
                                        )
                                    }
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {renderActionSummaryGrid(request)}  
                    </Card.Body>
                </Card>

                <Card className="mb-3">
                    <Card.Header>
                        <Row>
                            <Col  className="fs-5">
                                <strong className="mx-2 colorDark">Exceptions Applied</strong>
                            </Col>  
                            {request.id > 0 && (userInfo?.isAdministrator)?
                                        (
                                            <Col className="textAlign-end">                                                
                                                <Link to={pageRouteUrls.MngExceptions(id)} 
                                                    state={{previousPageUrls: [...previousPageUrls,pageRouteUrls.Request_Details(id)] }} 
                                                    className="btn btn-success">Manage Exception</Link>
                                            </Col>
                                        ) : (
                                            <></>
                                        )
                                    }
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {renderExceptionsTable(request.requestExceptions)} 
                    </Card.Body>
                </Card>
            
            </>)}
      </Layout>
    )
}
export default FOIPRequestDetails  