



export class NotificationConfigModel
{
    id:number=0;
    sendNotifications:boolean=false;
    emailAddress:string|null=null;
    dueDateNotificationDays:number|null=null;
}
  