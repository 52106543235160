import { AccountInfo, TokenClaims } from "@azure/msal-common";
import { AccessTypeEnum } from "../helper/Enums";

export default class UserModel //extends AccountInfo 
{ 
    name?: string;
    idToken?: string;
    idTokenClaims?: TokenClaims & {
        [key: string]: string | number | string[] | object | undefined | unknown;
    };
    nativeAccountId?: string;
    role:AccessTypeEnum[]|undefined;
    isAdministrator:boolean; 
    isVisitor:boolean; 
    constructor(info: AccountInfo) {  
        this.name=info.name;
        this.idToken=info.idToken;
        this.idTokenClaims=info.idTokenClaims;
        this.nativeAccountId=info.nativeAccountId;
        this.role=info.idTokenClaims?.roles?.map((role):AccessTypeEnum => 
        {
            switch(role)
            {
                case 'Visitor':
                    return AccessTypeEnum.Visitor;  
                case 'Admin':
                    return AccessTypeEnum.Administrator; 
                default:
                    return AccessTypeEnum.None;
            }
        }); 
        this.isAdministrator= this.role?.includes(AccessTypeEnum.Administrator)??false; 
        this.isVisitor= this.role?.includes(AccessTypeEnum.Visitor)??false; 
      }
};
