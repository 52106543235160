import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";

// FUNCTION TO DETECT AND CONVERT JS DATE FORMAT TO YYYY-MM-DD FORMAT A
export const returnDate = (date: Date) => {
  return typeof date === "string" ? date : getYYYYMMDD(date);
};
// FUNCTION TO CONVERT JS DATE FORMAT TO YYYY-MM-DD FORMAT
export const getYYYYMMDD = (date: Date) => {
  if (!date) return;
  let month = date.getMonth() + 1;
  let day = date.getDate();
  return `${date.getFullYear()}-${
    month < 10 ? "0" + month : month
  }-${day< 10 ? "0" + day : day}`;
};
// FUNCTION TO CALCULATE AGE FORMAT DATE
export const getAgeFromDOB = (date: Date) => {
  const today = new Date();
  let yearDiff = today.getFullYear() - date.getFullYear();
  let monthDiff = today.getMonth() - date.getMonth();
  let dayDiff = today.getDate() - date.getDate();
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) return yearDiff - 1;
  return yearDiff;
};
// FUNCTION TO RETURN DATE FROM A DATE/TIME STRING
export const returnDateFromDateTimeStr = (date: string) => {
  if (!date) return "";
  let d = date.split("T");
  return d[0];
};

export const Format=(dateReceived:Date|undefined|null, dateFormat:string):string=>
{
    if(dateReceived)
        return format(dateReceived, dateFormat);
    else
        return "--";
}

export const validateDate = (date: Date|undefined|null, fieldName: string,minDate:Dayjs|null|undefined,minDateErrMsg:string|null|undefined,maxDate:Dayjs|null|undefined,maxDateErrMsg:string|null|undefined):any => {
  if(!date) return true;
  let formattedDate = dayjs(date);
// console.log(date)
// console.log(formattedDate.isValid())
  if (!formattedDate.isValid()) {
      return `Invalid Date. Please enter a valid ${fieldName}.`;
  }

  if (minDate && formattedDate.isBefore(minDate, 'day')) {
console.log(minDate)
      return minDateErrMsg;
  } 

  if (maxDate && formattedDate.isAfter(maxDate, 'day')) {
    return maxDateErrMsg;
  } 
  return true;
};

export const validateDate2 = (date: Date|undefined|null, fieldName: string,dateMin:Date|null|undefined,minDateErrMsg:string|null|undefined,dateMax:Date|null|undefined,maxDateErrMsg:string|null|undefined):any => {
  if(!date) return true; 

  let minDate=dateMin? dayjs(dateMin):undefined;
  let maxDate= dateMax?dayjs(dateMax):undefined;
  let formattedDate = dayjs(date);

  if (!formattedDate.isValid()) {
      return `Invalid Date. Please enter a valid ${fieldName}.`;
  }

  if (minDate && formattedDate.isBefore(minDate, 'day')) {
      return minDateErrMsg;
  } 

  if (maxDate && formattedDate.isAfter(maxDate, 'day')) {
    return maxDateErrMsg;
  } 
  return true;
}; 

export const calculateLifecycle=(date:Date|Dayjs|undefined|null,curDate:Date|undefined|null):number=>
{
  const today =curDate? new Date(   curDate.getFullYear(),   curDate.getMonth(),   curDate.getDate() ): 
                        new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate() );  
  if(date)
  { 
    if (dayjs.isDayjs(date))  
      date = date.toDate(); 
    date=new Date(date.getFullYear(),date.getMonth(),date.getDate() ); 
    
    const date2 = dayjs(today);
    const days = date2.diff(date , 'days');
    return days; 
  }
  else
    return 0;
}

export const nullableDatesAreSame = (date1:Date|null|undefined, date2:Date|null|undefined): boolean => {
  return (!date1 && !date2) || dayjs(date1).isSame(date2, "day");
}

