import React from "react";
import {Card, Table} from "react-bootstrap";
import RequestModel from "../../models/RequestModel";
import {Link} from "react-router-dom";
import {pageRouteUrls} from "../../helper/PageRouteUrls";
import {Format} from "../../helper/DateUtilities";
import {DateFormat} from "../../helper/Constants";
import styled from "styled-components";
import ToolTipster from "../ToolTipster";

const StyledTable = styled(Table)<{ fill: string }>`
  & thead tr th {
    background-color: ${p => p.fill};
    color: white;
  }
`;

const DashboardRequestCard = ({cardTitle, fill, rowName, requests}: any) => {
    return (
        <Card className="same-height">
            <Card.Body>
                <Card.Title>{cardTitle}</Card.Title>

                <StyledTable fill={fill} className={"HomeTable"} bordered hover size="sm">
                    <thead>
                    <tr>
                        <th>File Number</th>
                        <th>Due Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {requests?.map((rqst: RequestModel, index: any) => (
                        <tr key={`${rowName}${index}`}>
                            <ToolTipster title={rqst.requestSummary}>
                                <td><Link to={pageRouteUrls.Request_Details(rqst.id)}>{rqst.fileNumber}</Link></td>
                            </ToolTipster>
                            <td>{Format(rqst.dueDate, DateFormat)}</td>
                        </tr>
                    ))}
                    </tbody>
                </StyledTable>

            </Card.Body>
        </Card>
    );
};

export default DashboardRequestCard;
