import React, {useContext} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {blockInvalidNumberChar} from "../../../../helper/ValidationUtilities";
import {useFormContext} from "react-hook-form";
import ActionEditContext from "../ActionEditContext";

const PaymentRequestedEdit = () => {
    const actionEditContext = useContext(ActionEditContext);
    if(actionEditContext == null){throw new Error("ActionEditContext is not initialized.");}
    const {
        actionEditFormState: {register, formState: { errors} }
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>Fee Amount</Form.Label>
                <Form.Control
                    type="number" {...register("fee", {
                        required: 'Fee Amount is required.',
                        min: { value: 0, message: 'Please enter a valid Fee Amount.' },
                        max: { value: 99999, message: 'Please enter a valid Fee Amount.' }
                    })}
                    onKeyDown={blockInvalidNumberChar}
                    placeholder="Enter the fee amount in CAD"
                    className="required"/>
                <ErrorMessage errors={errors} name="fee" render={({message}) => <span className="error-message">{message}</span>}/>
            </Form.Group>
        </Row>
    );
};

export default PaymentRequestedEdit;
