import React, {ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {Row,Col,Card,Form,Button } from "react-bootstrap"; 
import ApplicantModel from "../../models/ApplicantModel";  
import { useForm, SubmitHandler, Controller  } from "react-hook-form";
import { applicantService } from "../../service/ApplicantService";
import Layout from "../../component/Layout"; 
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";  
import {Countries,DateFormat,EmailExpValidator,Provinces} from "../../helper/Constants";   
import { ErrorMessage } from "@hookform/error-message";
import { pageRouteUrls } from "../../helper/PageRouteUrls";  
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";  
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {LoadingSpinner} from "../../component/LoadingSpinner"; 
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { validateDate } from "../../helper/DateUtilities";
import InputMask from 'react-input-mask';
import ApplicationContext from "../../contexts/ApplicationContext";
import ValidationAlert from "../../component/ValidationAlert"; 
 

export interface Props {  
} 
const ApplicantEdit: React.FC<Props> = props =>{     
    const location = useLocation();
    const [loading, setLoading] =useState<boolean>(false);
    const [errContactMsg, setErrContactMsg] =useState<string|undefined>(undefined); 
    const [btnDisable,setBtnDisable]=useState<boolean>(false);
    // const [isCountryCanada,setIsCountryCanada]=useState<boolean>(true); 
    const { sourceRequestId } =(location?.state? location.state:-1) as { sourceRequestId: number};
    const { previousPageUrls } = (location?.state ? location.state : []) as { previousPageUrls: string[] };
    const { id } = useParams();
    const namesValidationExp= /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.\-\'\s@.]+$/;
    const addressValidationExp=/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.\-\'\s\#\@\%\&\/@.]+$/;
    const emptyMaskedPhoneValue='+1 (___) ___-____';
    // BREADCRUMB FOR LAYOUT 
    const breadcrumb: any =[];
    if(previousPageUrls && previousPageUrls[0] == pageRouteUrls.Search()) breadcrumb.push({ title: "Search", link: pageRouteUrls.Search()  });    
    if(previousPageUrls && previousPageUrls.length>0 && (previousPageUrls[previousPageUrls.length-1] == pageRouteUrls.Request_Add() || (previousPageUrls[previousPageUrls.length-1] == pageRouteUrls.Request_Edit(sourceRequestId) && sourceRequestId==0))) breadcrumb.push({ title:`New Request` ,link:previousPageUrls[previousPageUrls.length-1],state:{ previousPageUrls: previousPageUrls } });
    if(previousPageUrls && previousPageUrls.length>0 && previousPageUrls[previousPageUrls.length-1] == pageRouteUrls.Request_Edit(sourceRequestId) && sourceRequestId>0) breadcrumb.push({ title:`Edit Request` ,link:previousPageUrls[previousPageUrls.length-1],state:{ previousPageUrls: previousPageUrls } });
    if(previousPageUrls && previousPageUrls.length>0 && previousPageUrls[previousPageUrls.length-1] == pageRouteUrls.Request_Details(sourceRequestId)) breadcrumb.push({ title: `Request Details` ,link:previousPageUrls[previousPageUrls.length-1],state:{ previousPageUrls: previousPageUrls} });
    if(id && Number.parseInt(id)>0)
    {
      breadcrumb.push({ title: `Applicant Details`,link:pageRouteUrls.Applicant_Details(id),state:{ sourceRequestId: sourceRequestId??0, previousPageUrls: previousPageUrls } });
      breadcrumb.push({ title: `Edit Applicant` }); 
    }
    else
      breadcrumb.push({ title: `Add Applicant` }); 
  
    const [applicant,setApplicant]=useState<any>(new ApplicantModel()); 
    const {reset,control, register, handleSubmit,formState,getValues, clearErrors,setValue,watch } = useForm<ApplicantModel>({ shouldUnregister: false,defaultValues:new ApplicantModel()});  
    const navigate = useNavigate();
    const applicationContext = useContext(ApplicationContext);
    const countryValue = watch('country');
 
    useEffect(() => {
      //to prevent navigation 
      // if (formState.isDirty) {
      //   window.onbeforeunload = () => true
      // } else {
      //   window.onbeforeunload = null;
      // } 
    }, [formState.isDirty]);

    const isCountryCanada=():boolean=>countryValue.toLowerCase() === "canada";
    
    useEffect(()=>{

      clearErrors("postalCode");
      clearErrors("province");
    },[countryValue])
    useEffect(() => { 
      if(id)
      { 
        fetchData(parseInt(id));
      }  
     }, [id]);


     const fetchData = async (id:number) => {
      setBtnDisable(true);
      setLoading(true);
      const data =await applicantService.GetById(id); 
      // console.log(data)
      setApplicant(data);
       
      if(data)
      { 
        reset(data);
      }
      setBtnDisable(false);
      setLoading(false);
    } 
    const isValidPhoneNumber=(phonenumber:any):boolean=>
    {   
      if(phonenumber==emptyMaskedPhoneValue)
        return true;
      // Regex to check valid, International Phone Numbers
      let regex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/gm)
      if (phonenumber == null) {
          return false;
      }
      return (regex.test(phonenumber) == true);
    }  

    const isValidApplicant=(data: ApplicantModel):boolean =>{ 
      if(!data.phoneDay&&!data.phoneCell&&!data.phoneEvening&&!data.phoneFax&&!data.emailAddress)
      {
        setErrContactMsg("Please enter a Phone Number or Email Address.");
        return false;
      }
      return true;
    }
    const prepairApplicant=(data: ApplicantModel):ApplicantModel =>{
      if(data.phoneDay==emptyMaskedPhoneValue) data.phoneDay='';
      if(data.phoneCell==emptyMaskedPhoneValue) data.phoneCell='';
      if(data.phoneEvening==emptyMaskedPhoneValue) data.phoneEvening='';
      if(data.phoneFax==emptyMaskedPhoneValue) data.phoneFax='';
 
      return data;
    }
    const onSubmit: SubmitHandler<ApplicantModel> =async (data) =>
    {
            setBtnDisable(true);
            setErrContactMsg(undefined); 
            data=prepairApplicant(data); 
            if(!isValidApplicant(data))
            {
              setBtnDisable(false);
              return;
            }
            if(data.id==0 || formState.isDirty)
            {
              setApplicant(data); 
              const responceSave=await applicantService.Save(data, applicationContext);
              if(responceSave)
              {  
                  Navigate_success(responceSave); 
              }
            }
            else Navigate_success(data);
          
        setBtnDisable(false);
    }; 
    
    const Navigate_success=(responceSave: ApplicantModel)=>{
    
        if(sourceRequestId && sourceRequestId>0) // from existed request
        {
            if(previousPageUrls && previousPageUrls.length>=1 && previousPageUrls[previousPageUrls.length-1]== pageRouteUrls.Request_Edit(sourceRequestId))
              navigate(pageRouteUrls.Request_Edit(sourceRequestId),{ state:{ quApplicantId: responceSave.id , previousPageUrls: previousPageUrls }  }); 
            else
              navigate(pageRouteUrls.Request_Details(sourceRequestId),{ state:{ quApplicantId: responceSave.id , previousPageUrls: previousPageUrls }  }); 
        }
        else if(previousPageUrls && previousPageUrls.length>=1 && previousPageUrls[previousPageUrls.length-1])
          navigate(previousPageUrls[previousPageUrls.length-1], { state: {quApplicantId: responceSave.id , previousPageUrls: previousPageUrls } });
        else
        { // go to home page 
          navigate(pageRouteUrls.Home());
        }
    
    } 

    return (
      <Layout breadcrumList={breadcrumb}  >  
        <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off"> 
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            
            <Card className="mb-3">
              <Card.Header>
                <Row>
                  <Col  className="fs-5">
                    <strong className="mx-2 colorDark">{id?'Edit':'Add'} Applicant</strong>
                  </Col> 
                  <Col className="textAlign-end"> 
                    <Button variant="primary" type="submit" disabled={btnDisable}>Save</Button>
                    <Link 
                      to={id && Number.parseInt(id)>0 ? pageRouteUrls.Applicant_Details(id) : 
                        (previousPageUrls && previousPageUrls.length>0? previousPageUrls[previousPageUrls.length-1] /*pageRouteUrls.Request_Add()*/:pageRouteUrls.Home())
                      } 
                      state={{ sourceRequestId: sourceRequestId??0, previousPageUrls: previousPageUrls }} 
                      className="btn btn-dark">Cancel</Link>
                  </Col> 
                </Row>
              </Card.Header>
              <Card.Body>
              {loading ? (
                  <LoadingSpinner />
                  ) : (
                    <>
                      <Row className="mb-3">  
                        <Form.Group as={Col} controlId="formfirstName">
                          <Form.Label>First Name</Form.Label>  
                          <Form.Control type="text" autoFocus {...register("firstName", 
                            { 
                              pattern: {
                                value: namesValidationExp ,
                                message: 'Please enter a valid First Name.'
                              },
                              required: 'The First Name is required.',
                              maxLength: { value: 35, message: "First name cannot exceed 35 characters." } 
                            })} 
                            className="required" placeholder="Enter your First name" />
                          <ErrorMessage errors={formState.errors} name="firstName"render={({ message }) => <span className="error-message" >{message}</span>}/> 
                        </Form.Group>

                        <Form.Group as={Col} controlId="formlastName">
                          <Form.Label>Last Name</Form.Label> 
                          <Form.Control type="text" {...register("lastName", 
                          { 
                            pattern: {
                              value: namesValidationExp, 
                              message: 'Please enter a valid Last Name.'
                            },
                            required: 'The Last Name is required.',
                            maxLength: { value: 35, message: "Last name cannot exceed 35 characters." } 
                          })} className="required"  placeholder="Enter your Last name" /> 
                          <ErrorMessage errors={formState.errors} name="lastName"render={({ message }) => <span className="error-message" >{message}</span>}/>

                        </Form.Group>

                        <Form.Group as={Col} controlId="formdateOfBirth">
                          <Form.Label>Date of Birth</Form.Label>                   
                          <Controller
                              control={control}
                              name='dateOfBirth'
                              rules={{ 
                                validate: (date) => validateDate(date, "Date of Birth",undefined,undefined,dayjs(new Date()),'The Date of Birth cannot be a future date.')
                              }}
                              render={({ field }) => (
                                <DatePicker
                                  views={['year', 'month', 'day']}   
                                  value={field.value?dayjs(field.value):null}
                                                                  slotProps={{ textField : { 
                                                                      placeholder:"Enter your date of birth", 
                                                                      className: "form-control" 
                                        } }}
                                  onChange={(date) => field.onChange(date)}
                                />
                            )}
                            />
                          <ErrorMessage errors={formState.errors} name="dateOfBirth"render={({ message }) => <span className="error-message" >{message}</span>}/>

                        </Form.Group>
                      </Row>
                      
                      <Row className="mb-3"> 
                        <Col>  
                            <Card className="mb-2 same-height">
                              <Card.Header><strong>Address Information</strong></Card.Header>
                              <Card.Body> 
          
                                  <div className="row mb-3"> 
                                      <div className="col-sm-3" > 
                                        <Form.Label sm={4}>Address 1</Form.Label>
                                        
                                      </div>
                                      <div className="col-sm-8" >
                                        <Form.Control type="text" {...register("address1",
                                          { 
                                            pattern: {
                                              value: addressValidationExp, 
                                              message: 'Please enter a valid Address 1'
                                            },
                                            required: 'The Address 1 is required.',
                                            maxLength: { value: 60, message: "Address 1 cannot exceed 60 characters." }
                                          })} className="required" placeholder="Enter your address 1" />
                                        <ErrorMessage errors={formState.errors} name="address1" render={({ message }) => <span className="error-message" >{message}</span>}/>
                                      </div> 
                                  </div>
                                  
                                  <div className="row mb-3"> 
                                      <div className="col-sm-3" > 
                                          <Form.Label>Address 2</Form.Label>
                                      </div>
                                      <div className="col-sm-8" >
                                          <Form.Control type="text" {...register("address2",
                                          { 
                                            pattern: {
                                              value: addressValidationExp, 
                                              message: 'Please enter a valid Address 2'
                                            }, 
                                            maxLength: { value: 60, message: "Address 2 cannot exceed 60 characters." }
                                          })} placeholder="Enter your address 2" />
                                          <ErrorMessage errors={formState.errors} name="address2" render={({ message }) => <span className="error-message" >{message}</span>}/>
                                      </div> 
                                  </div> 

                                  <div className="row mb-3"> 
                                      <div className="col-sm-3" > 
                                        <Form.Label sm={4}>City</Form.Label>
                                        
                                      </div>
                                      <div className="col-sm-8" >
                                        <Form.Control type="text" {...register("city",
                                        { 
                                          pattern: {
                                            value: namesValidationExp ,
                                            message: 'Please enter a valid City.'
                                          },
                                          required: 'The City is required.',
                                          maxLength: { value: 50, message: "City cannot exceed 50 characters" }
                                        })} className="required"  placeholder="Enter your city" />
                                        <ErrorMessage errors={formState.errors} name="city" render={({ message }) => <span className="error-message" >{message}</span>}/>
                                      </div> 
                                  </div>
                                  
                                                             
                                  <div className="row mb-3"> 
                                      <div className="col-sm-3"> 
                                          <Form.Label>Province</Form.Label>
                                      </div>
                                      <div className="col-sm-8">
                                          <Form.Control 
                                              type="text" 
                                              {...register("province", { 
                                                  pattern: {
                                                      value: namesValidationExp,
                                                      message: 'Please enter a valid Province Name.'
                                                  },
                                                  required: isCountryCanada() && 'Province is required.',
                                              })} 
                                              className={isCountryCanada() ? "required" : ''} 
                                              placeholder="Enter your province Name" 
                                          />
                                          <ErrorMessage errors={formState.errors} name="province" render={({ message }) => <span className="error-message">{message}</span>}/>
                                      </div>
                                  </div>                                
                                  
                                  <div className="row mb-3"> 
                                      <div className="col-sm-3" > 
                                          <Form.Label>Country</Form.Label>
                                          
                                      </div>
                                      <div className="col-sm-8">
                                        <Form.Control 
                                            type="text" 
                                            {...register("country", { 
                                                pattern: {
                                                    value: namesValidationExp,
                                                    message: 'Please enter a valid Country Name.'
                                                },
                                                required: 'Country is required.',
                                            })} 
                                            className="required" 
                                            placeholder="Enter your Country Name" 
                                        />
                                        <ErrorMessage errors={formState.errors} name="country" render={({ message }) => <span className="error-message">{message}</span>}/>
                                    </div>
                                  </div>
                                 
                                    <div className="row mb-3"> 
                                      <div className="col-sm-3" > 
                                          <Form.Label>Postal Code</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >
                                        <Form.Control type="text" {...register("postalCode",
                                        { 
                                          pattern: {
                                            value: namesValidationExp ,
                                            message: 'Please enter a valid Postal Code.'
                                          },
                                          required: isCountryCanada() && 'The Postal Code is required.',
                                          maxLength: { value: (isCountryCanada()?7:20), message: "Please enter a valid Postal Code." } ,
                                          validate: (value) => {
                                            if(isCountryCanada())
                                              return postcodeValidator(value, 'CA') || 'Please enter a valid Postal Code';
                                            return true;
                                          }
                                        })} 
                                        className={isCountryCanada() ? "required" : ''} placeholder="Enter your postal or zip code" />
                                        <ErrorMessage errors={formState.errors} name="postalCode" render={({ message }) => <span className="error-message" >{message}</span>}/>
                                    </div>
                                  </div>
                                  
                              </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                          <Card className="mb-2 same-height">
                            <Card.Header><strong>Contact Information</strong></Card.Header>                    
                            <Card.Body> 
                                    {errContactMsg?
                                    (
                                      <Row className="mb-3">
                                          <ValidationAlert message={errContactMsg}/>
                                      </Row>
                                    ):(<></>)
                                  }
                                  <Row className="mb-3"> 
                                      <div className="col-sm-3" >
                                        <Form.Label>Day Phone #</Form.Label>
                                      </div>
                                      <div className="col-sm-8" >
                                        <Controller
                                            name="phoneDay"
                                            control={control}
                                            rules={{
                                              validate: (value) => (value?isValidPhoneNumber(value):true)|| 'Invalid Phone #'
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                              <InputMask className="form-control"
                                                mask='+1 (999) 999-9999' 
                                                placeholder="Enter your daytime phone number" 
                                                value={value}
                                                onChange={onChange}>
                                              </InputMask>  
                                            )}
                                          />
                                        <ErrorMessage errors={formState.errors} name="phoneDay" render={({ message }) => <span className="error-message" >{message}</span>}/>
                                      </div> 
                                  </Row> 
                                  <Row className="mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Evening Phone #</Form.Label>
                                    </div>
                                    <div className="col-sm-8" > 

                                        <Controller
                                            name="phoneEvening"
                                            control={control}
                                            rules={{
                                              validate: (value) => (value?isValidPhoneNumber(value):true)|| 'Invalid Phone #'
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                              <InputMask className="form-control"
                                                mask='+1 (999) 999-9999'
                                                placeholder="Enter your evening phone number" 
                                                value={value}
                                                onChange={onChange}>
                                              </InputMask> 
                                            )}
                                          />
                                        <ErrorMessage errors={formState.errors} name="phoneEvening" render={({ message }) => <span className="error-message" >{message}</span>}/>
        
                                    </div>
                                  </Row>
                                  <Row className="mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Cell Phone #</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >  

                                        <Controller
                                            name="phoneCell"
                                            control={control}
                                            rules={{
                                              validate: (value) => (value?isValidPhoneNumber(value):true)|| 'Invalid Phone #'
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                              <InputMask className="form-control"
                                                mask='+1 (999) 999-9999'
                                                placeholder="Enter your cell phone number" 
                                                value={value}
                                                onChange={onChange}>
                                              </InputMask> 
                                            )}
                                          /> 
                                        <ErrorMessage errors={formState.errors} name="phoneCell" render={({ message }) => <span className="error-message" >{message}</span>}/>

                                    </div>
                                  </Row>
                                  <Row className="mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Fax #</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >  

                                        <Controller
                                            name="phoneFax"
                                            control={control}
                                            rules={{
                                              validate: (value) => (value?isValidPhoneNumber(value):true)|| 'Invalid Phone #'
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                              <InputMask className="form-control"
                                                mask='+1 (999) 999-9999'
                                                placeholder="Enter your fax phone number" 
                                                value={value}
                                                onChange={onChange}>
                                              </InputMask> 
                                            )}
                                          /> 
                                          <ErrorMessage errors={formState.errors} name="phoneFax" render={({ message }) => <span className="error-message" >{message}</span>}/>
                                    </div>
                                  </Row>
                                  <Row className="mb-3">
                                    <div className="col-sm-3" >
                                      <Form.Label>Email Address</Form.Label>
                                    </div>
                                    <div className="col-sm-8" >
                                      <Form.Control type="email" {...register("emailAddress", {                                        
                                                maxLength: { value:320, message: "Please enter a valid email." } ,
                                                pattern: {
                                                  value:EmailExpValidator ,
                                                  message: "Please enter a valid email"
                                                }
                                              })}  
                                              placeholder="Email address" /> 
                                          <ErrorMessage errors={formState.errors} name="emailAddress" render={({ message }) => <span className="error-message" >{message}</span>}/> 
                                    </div>
                                  </Row> 
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  )}

              </Card.Body>
            </Card>
          </LocalizationProvider>

        </Form>
            
      </Layout>
    );
  }
  export default ApplicantEdit

