
export const config = {
    clientId: `${process.env.REACT_APP_CLIENTID}`,
    authority: `${process.env.REACT_APP_AUTHORITY}`, 
    redirectUri: `${process.env.REACT_APP_REDIRECTURI}`,
    scope: `${process.env.REACT_APP_SCOPE}`,

    ApplicationTitle:`${process.env.REACT_APP_APPLICATION_TITLE}`,
    ApplicationShortName:`${process.env.REACT_APP_APPLICATION_SHORT_NAME}`,
    ApplicationEnvironment: `${process.env.REACT_APP_APPLICATION_ENVIRONMENT_NAME}`,
    Version:`${process.env.REACT_APP_VERSION}`,

    baseUrl: `${process.env.REACT_APP_BASEURL}`,
    timeout: `${process.env.REACT_APP_TIMEOUT}`,
    retryCount: `${process.env.REACT_APP_RETRY_COUNT}`,
    retryDelay: `${process.env.REACT_APP_RETRY_DELAY}` 
}