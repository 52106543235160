import React, {useContext} from "react";
import {Form, Row, Col} from "react-bootstrap";
import {Controller, useFormContext} from "react-hook-form";
import {Checkbox} from "@mui/material";
import ActionEditContext from "../ActionEditContext";

const ResponseReceivedEdit = () => {
    const actionEditContext = useContext(ActionEditContext);
    if(actionEditContext == null){throw new Error("ActionEditContext is not initialized.");}
    const {
        actionEditFormState: {control}
    } = actionEditContext;

    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>Response Received</Form.Label>
                <Controller
                    name="responseReceived"
                    control={control}
                    render={({field}) =>
                        <Checkbox
                            {...field}
                            inputRef={field.ref}
                            checked={!!field.value}
                        />}
                />
            </Form.Group>
        </Row>
    );
};

export default ResponseReceivedEdit;
