import React from "react";
import {Col, Row} from "react-bootstrap";
import {Alert, AlertTitle} from "@mui/material";

const ValidationAlert = ({message}: any) => {
    if (!message) return (<></>);

    function toValidationMessage(validationObject: any) {
        return Object.keys(validationObject).map(function (key) {
            let validationMessages = [];
            for (let index in validationObject[key]) {
                validationMessages.push(`${validationObject[key][index]}`);
            }

            return validationMessages;
        });
    }

    return (
        <Row className="mb-3">
            <Col>
                <Alert key={'error'} severity="error" className="border border-danger-subtle">
                    {
                        typeof (message) !== "object" ? (<strong>{message}</strong>)
                            : (
                                <>
                                    <AlertTitle><strong>Validation Errors</strong></AlertTitle>
                                    <ul>
                                        {toValidationMessage(message).map((key: any) => (
                                            <li key={key}>{key}</li>
                                        ))}
                                    </ul>
                                </>
                            )
                    }
                </Alert>
            </Col>
        </Row>
    );
};

export default ValidationAlert;