import dayjs, {Dayjs} from "dayjs";
import {useState} from "react";
import RequestActionTypeModel from "../../../../models/RequestActionTypeModel";
import {RequestActionModel} from "../../../../models/RequestActionModel";
import {useForm} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {actionTypeService} from "../../../../service/ActionTypeService";
import {actionService} from "../../../../service/ActionService";
import {requestService} from "../../../../service/RequestService";

function useActionEdit() {
    const {id, requestId} = useParams();
    const [allActionTypes, setAllActionTypes] = useState<RequestActionTypeModel[]>([]);
    const [actionType, setActionType] = useState<RequestActionTypeModel>();
    const [actionData, setActionData] = useState<RequestActionModel>(new RequestActionModel());
    const [minBringForwardDate, setMinBringForwardDate] = useState<Dayjs | null>(null);
    const [maxBringForwardDate, setMaxBringForwardDate] = useState<Dayjs | null>(null);
    const [minActionDate, setMinActionDate] = useState<Dayjs>();
    const [maxActionDate, setMaxActionDate] = useState<Dayjs>();
    const [btnDisable,setBtnDisable]=useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const {previousPageUrls} = (location?.state ? location.state : []) as { previousPageUrls: string[] };
    const actionEditFormState = useForm<RequestActionModel>({defaultValues: new RequestActionModel()});
    const navigate = useNavigate();

    const actionEditState = {
        id, requestId,
        actionData, setActionData,
        actionType, setActionType,
        allActionTypes, setAllActionTypes,
        minBringForwardDate, setMinBringForwardDate,
        maxBringForwardDate, setMaxBringForwardDate,
        minActionDate, setMinActionDate,
        maxActionDate, setMaxActionDate,
        btnDisable, setBtnDisable,
        loading, setLoading,
        previousPageUrls
    };

    const { setValue} = actionEditFormState;

    const setActionTypeById = (actionTypeId: number) => {
        let dataActionType = actionEditState.allActionTypes.find(i => i.id === Number(actionTypeId));
        actionEditState.setActionType(dataActionType);
    };

    const setBringForwardMinMaxDates = (actionDate: Dayjs|null) => {
        if (!actionEditState.actionType || !actionDate) return;

        actionEditState.setMinBringForwardDate(actionDate!.add(actionEditState.actionType.bringForwardMinDaysForward, 'day'));
        actionEditState.setMaxBringForwardDate(actionDate!.add(actionEditState.actionType.bringForwardMaxDaysForward, 'day'));
    };

    const loadAllActionTypes = () => {
        const fetchData = async () => {
            const allActionTypes = await actionTypeService.GetAllForRequest(Number(requestId), id ? Number(id) : 0);
            setAllActionTypes(allActionTypes);
        }

        setLoading(true);
        fetchData().then(() => { setLoading(false); });
    };

    const loadAction = () => {
        if (!id) return;

        const fetchData = async () => {
            const data = await actionService.GetById(parseInt(id));
            if (!data) return;

            setActionData(data!);
            setActionTypeById(data.requestActionTypeId);

            actionEditFormState.reset(data!);
        }

        fetchData().then();
    };

    const loadRequest = () => {
        if (!requestId) return;
        const fetchData = async () => {
            const rqst = await requestService.GetById(parseInt(requestId));
            setMinActionDate(dayjs(rqst?.dateReceived));
            setMaxActionDate(dayjs());
            setBringForwardMinMaxDates(dayjs(actionData.actionDate));
            actionData.requestFileNumber = rqst?.fileNumber ?? null;
        }

        fetchData().then();
        setValue("requestId", parseInt(requestId));
    };

    return {
        actionEditState,
        actionEditFormState,
        setActionTypeById,
        setBringForwardMinMaxDates,
        loadAllActionTypes,
        loadAction,
        loadRequest,
        navigate
    };
}

export default useActionEdit;