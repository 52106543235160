import React from "react";
import {Col, Form, Row} from "react-bootstrap";

const PaymentRequestedDetails = ({actionData}: any) => {
    return (
        <Row className="mb-3">
            <Form.Group as={Col} lg="6">
                <Form.Label>Fee Amount</Form.Label>
                <span className="ps-4">{actionData.fee} </span>
            </Form.Group>
        </Row>
    );
};

export default PaymentRequestedDetails;
