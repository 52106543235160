import axios from "axios";
import path from "path";
import { requestUtility } from "../helper/RequestUtility"; 
import { RequestActionModel } from "../models/RequestActionModel";
import { RequestActionTypeEnum } from "../helper/Enums";
import ApplicantModel from "../models/ApplicantModel";
import RequestModel from "../models/RequestModel";
import {ApplicationContextType} from "../contexts/ApplicationContext";

export class actionService {

    private static prepare(data:RequestActionModel) :RequestActionModel
    {
        if(data?.actionDate)
            data.actionDate=new Date( data.actionDate);
        if(data?.bringForwardDate)
            data.bringForwardDate=new Date( data.bringForwardDate);

        return data;
    }

    public static async GetById(id: number): Promise<RequestActionModel | undefined> {
        try {
            if (id > 0) {
                const {data} = await requestUtility.get(`/api/v1/RequestAction/${id}`);
                //  console.log(data)
                return data ? this.prepare(data) : data;
            }
            return new RequestActionModel();
        } catch (e) {
            requestUtility.handleError(e);
        }

        return undefined;
    }
     
    public static async Save(data:RequestActionModel, applicationContext?: ApplicationContextType ):Promise<RequestActionModel|undefined>{
        try{
            const response  =
                data.id>0 ?
                    await requestUtility.put(`/api/v1/RequestAction/Update`,data):
                    await requestUtility.post(`/api/v1/RequestAction/Add`,data);

            if(response)
                return response as RequestActionModel;
        }catch(e)
        {
            requestUtility.handleError(e, applicationContext);
        }
        return undefined;
    }

    public static async Delete(id:number):Promise<boolean|undefined>{
        try{
            const response  =
                await requestUtility.deleteById(`/api/v1/RequestAction/${id}`);
            if(response)
                return response as boolean;
        }catch(e)
        {
            requestUtility.handleError(e);
        }
        return undefined;
    }
  };