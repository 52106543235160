import { requestUtility } from "../helper/RequestUtility";
import RequestModel from "../models/RequestModel";
import {RequestActionModel} from "../models/RequestActionModel";
import SearchParamModel from "../models/SearchParamModel";
import { getYYYYMMDD } from "../helper/DateUtilities";
import RequestsSummaryModel from "../models/RequestsSummaryModel";
import ReportParamModel from "../models/ReportParamModel";
import {SearchCriteriaEnum} from "../helper/Enums";
import {ApplicationContextType} from "../contexts/ApplicationContext";

export class requestService {
    private static downloadAndOpenFile(fileData: any, fileName: string){
        const pdfBlob = new Blob([fileData], {type: 'application/pdf'});
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, '_blank');

        const fileLink = document.createElement('a');
        fileLink.href = pdfUrl;
        fileLink.download = fileName;
        fileLink.click();
        fileLink.remove();

        window.URL.revokeObjectURL(pdfUrl);
    }

    private static prepareActions(data:RequestActionModel[]) :RequestActionModel[]
    {
        data.forEach(a => {
            if(a?.actionDate)
                a.actionDate=new Date(a.actionDate);
            if(a?.bringForwardDate)
                a.bringForwardDate=new Date(a.bringForwardDate)
        });

        return data;
    }
     
    private static prepair(data:RequestModel) :RequestModel
    {
        if(data?.dateReceived) data.dateReceived=new Date( data.dateReceived);
        if(data?.dueDate) data.dueDate=new Date( data.dueDate);
        if(data?.completedDate) data.completedDate=new Date( data.completedDate); 
        if(data?.requestActions) data.requestActions = this.prepareActions(data.requestActions);
             
        if(! data.sharepointUrl) data.sharepointUrl=decodeURIComponent(data.sharepointUrl);

        return data;
    }
    static async GetById(id:number ):Promise<RequestModel|undefined>{ 
        try{  
            if(id>0)
            {  
                const { data } = await requestUtility.get(`/api/v1/Request/${id}`);  
                //  console.log(data) 
                return data? this.prepair(data):data;
            }     
            return new RequestModel();
        }catch(e)
        {
            requestUtility.handleError(e);
        }
        
    }
  
    static async SearchBy(searchParams:SearchParamModel|undefined ):Promise<RequestModel[]>{
        
        // console.log(searchParams) 
        if(searchParams && searchParams.searchType)
        {
            try{   
                let params=`SearchType=${Number(searchParams.searchType)}`;
                
                    if(searchParams.searchType== SearchCriteriaEnum.DateRange)
                    {
                        params+=`&ByDateType=${searchParams.byDateType}`; 
                        if(searchParams.byDateFrom)
                            params+=`&ByDateFrom=${getYYYYMMDD(new Date(searchParams.byDateFrom))}`; 
                        if(searchParams.byDateTo)
                            params+=`&ByDateTo=${getYYYYMMDD(new Date(searchParams.byDateTo))}`; 
                    }
                    else if(searchParams.searchType== SearchCriteriaEnum.Applicant)
                    {
                        params+=`&ByApplicantName=${searchParams.byApplicantName}`; 
                    }
                    else if(searchParams.searchType==SearchCriteriaEnum.FileNumber)
                    {
                        params+=`&ByFileNumber=${searchParams.byFileNumber}`;  
                    }
                    else if(searchParams.searchType== SearchCriteriaEnum.Keyword)
                    {
                        params+=`&ByKeywordTxt=${encodeURIComponent( (searchParams.byKeywordTxt??'').replace("https://","https_//").replace("http://","http_//") )}`; 
                    }
                    else if(searchParams.searchType== SearchCriteriaEnum.RequestType)
                    {
                        params+=`&ByRequestTypeId=${Number(searchParams.byRequestTypeId)}`; 
                    }
                    else if(searchParams.searchType== SearchCriteriaEnum.ActiveRequests)
                    { 
                    }
                    else if(searchParams.searchType== SearchCriteriaEnum.CompletedRequests)
                    { 
                    }
                    
                const url=`/api/v1/Request/Search?${params}`;
                // console.log(url)
                let { data } = await requestUtility.get(url);  
                if(data)
                {
                    for (let i = 0; i < data.length; i++) {
                        data[i]= requestService.prepair(data[i]);
                      }
                } 
                return data as RequestModel[]; 
            }catch(e)
            {
                requestUtility.handleError(e);
            }
        }
        return [];   
    }
    static async GenerateReportBy(searchParams:ReportParamModel|undefined ):Promise<any>{
        
        // console.log(searchParams) 
        if (searchParams) {
            try {
                let dateFrom = getYYYYMMDD(new Date(searchParams.ByDateFrom!));
                let dateTo = getYYYYMMDD(new Date(searchParams.ByDateTo!));
                let fileName = `FOIPReport-From_${dateFrom}-To_${dateTo}.pdf`;

                const url = `/api/v1/Request/GenerateRequestsSummaryReport/${dateFrom}/${dateTo}/${searchParams.ReportTitle}`;
                const response = await requestUtility.getFile(url);

                this.downloadAndOpenFile(response.data, fileName);
            } catch (e) {
                requestUtility.handleError(e);
            }
        }
        return [];   
    }

    static async GetAll( ):Promise<RequestModel[]>{
        
             try{  
                 let { data } = await requestUtility.get(`/api/v1/Request/GetAll`);  
                 
                 if(data)
                 {
                     for (let i = 0; i < data.length; i++) {
                         data[i]= requestService.prepair(data[i]);
                       }
                 } 
                 return data as RequestModel[]; 
             }catch(e)
             {
                 requestUtility.handleError(e);
             }
        
         return [];   
    }
    static async GetRequestsSummary():Promise<RequestsSummaryModel|undefined>{
        
        try{  
            let { data } = await requestUtility.get(`/api/v1/Request/GetRequestsSummary`);  
            let summaryData=data as RequestsSummaryModel;
            if(summaryData)
            {
                for (let i = 0; i < summaryData.onTrackRequests.length; i++) 
                    summaryData.onTrackRequests[i]= requestService.prepair(summaryData.onTrackRequests[i]);
                for (let i = 0; i < summaryData.dueWeekRequests.length; i++) 
                    summaryData.dueWeekRequests[i]= requestService.prepair(summaryData.dueWeekRequests[i]);
                for (let i = 0; i < summaryData.overdueRequests.length; i++) 
                    summaryData.overdueRequests[i]= requestService.prepair(summaryData.overdueRequests[i]);
                for (let i = 0; i < summaryData.onHoldRequests.length; i++) 
                    summaryData.onHoldRequests[i]= requestService.prepair(summaryData.onHoldRequests[i]);
            }  
            return summaryData; 
        }catch(e)
        {
            requestUtility.handleError(e);
        }   
        return undefined;   
    }
    public static async Save(data:RequestModel, applicationContext?: ApplicationContextType ):Promise<RequestModel|undefined>{
        try{
            if(!data.disclosureTypeId ) data.disclosureTypeId=null;
            if(!data.dispositionTypeId) data.dispositionTypeId=null;
            if(!data.releaseStatusTypeId) data.releaseStatusTypeId=null;
            if(! data.deliveryMethodTypeId)data.deliveryMethodTypeId=null; 
            if(! data.sharepointUrl) data.sharepointUrl=encodeURIComponent(data.sharepointUrl);

            const response  =
            data.id>0 ?
                await requestUtility.put(`/api/v1/Request/Update`,data):
                await requestUtility.post(`/api/v1/Request/Add`,data);
            
            if(response)
                return response as RequestModel;
        }catch(e)
        {
            requestUtility.handleError(e, applicationContext);
        }
        return undefined;
    }
  };