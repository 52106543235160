import { requestUtility } from "../helper/RequestUtility";
import RequestActionTypeModel from "../models/RequestActionTypeModel";

export class actionTypeService {

    public static async GetAllForRequest(requestId: number, actionId: number):Promise<RequestActionTypeModel[]>{
        try{
            const { data } = await requestUtility.get(`/api/v1/RequestActionType/${requestId}/${actionId}/all`);
            return data as RequestActionTypeModel[];

        }catch(e)
        {
            requestUtility.handleError(e);
        }
        return [];
    }

    public static async GetAll():Promise<RequestActionTypeModel[]>{
        try{
            const { data } = await requestUtility.get("/api/v1/RequestActionType/all");
            return data as RequestActionTypeModel[];

        }catch(e)
        {
            requestUtility.handleError(e);
        }
        return [];
    }
};