 
import axios, { AxiosError } from "axios";
import axiosRetry from 'axios-retry';
import {config} from '../config.js';
import Swal from "sweetalert2";
import {SignedOutRequestStatus} from "./Constants";
import authUtility from "./AuthUtility";
import {ApplicationContextType} from "../contexts/ApplicationContext";

const axiosInstance: any  = axios.create({ baseURL: config.baseUrl, timeout:Number.parseInt(config.timeout), });

axiosRetry(axiosInstance, {
    retries: Number.parseInt(config.retryCount), // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount *  Number.parseInt(config.retryDelay); // time interval between retries
    } 
  });
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function (config: any) {
      return authUtility.addAuthorizedHeader(config, true);
    },
      function (error:any ) {
      // Do something with request error 
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response: any) => {
      if (response.status === 401) {
       return authUtility.handleLogout();
      }
      return response;
    },
    (err: any) => { 
         return Promise.reject(err); 
    }
  );
export default axiosInstance;
  
 
export class requestUtility {
 
    public static async getFile(apiUrl:string ){    
      return await axiosInstance.get(apiUrl, {responseType: 'blob'});
    }

    public static async get(apiUrl:string ){  
        return await axiosInstance.get(apiUrl );  
    }
  
    public static async post(apiUrl:string,data:any ){ 
        //  console.log( JSON.stringify(data))
        const response = await axiosInstance.post(apiUrl,  JSON.stringify(data)  ); 
        return response.data;
    } 
    public static async put(apiUrl:string,data:any ){ 
     // console.log( JSON.stringify(data))
     const response = await axiosInstance.put(apiUrl,  JSON.stringify(data) ); 
     return response.data;
    } 
    public static async delete(apiUrl:string,data:any ){ 
      //  console.log( JSON.stringify(data))
      const response = await axiosInstance.delete(apiUrl,
        {
          data: JSON.stringify(data)
        }
         ); 
      return response.data;
    }
    public static async deleteById(apiUrl:string ){
        const response = await axiosInstance.delete(apiUrl);
        return response.data;
    }

    public static handleError(e:any, applicationContext?: ApplicationContextType)
    {
      if(e === SignedOutRequestStatus) return;

      console.log(e);

      const err = e as AxiosError;
      let errHtml='';
      let title=this.getErrorTitle(err.request?.status);

      switch(err.request?.status)
      {
        case 401:
          return authUtility.handleLogin();
        case 404: 
          errHtml="Requested record was not found.<br>";
          Swal.fire({html: errHtml,icon: 'warning'}).then(()=>{ window.location.href = "/"; });
          break; 
        case 400: 
          errHtml=err.request && err.request.response?err.request.response : `Something went wrong!<br><small>${err.message}</small>`;
          Swal.fire({title: title,html: errHtml,icon: 'warning'});
          break;
        case 422:
          this.fireValidationAlert(e, title, applicationContext);
          break;
        default:
          errHtml=`Something went wrong!<br><small>${err.message}</small>`; 
          Swal.fire({title: title,html: errHtml,icon: 'error'});
      } 
    }
    private static getErrorTitle(errorCode:number):string
    {
      switch(errorCode)
      {
        case 400: return 'Bad Request';
        case 401: return 'Unauthorized';
        case 402: return 'Payment Required';
        case 403: return 'Forbidden';
        case 404: return 'Not Found';
        case 405: return 'Method Not Allowed';
        case 406: return 'Not Acceptable';
        case 407: return 'Proxy Authentication Required';
        case 408: return 'Request Timeout';
        case 409: return 'Conflict';
        case 410: return 'Gone';
        case 411: return 'Length Required';
        case 412: return 'Precondition Failed';
        case 413: return 'Payload Too Large';
        case 414: return 'URI Too Long';
        case 415: return 'Unsupported Media Type';
        case 416: return 'Range Not Satisfiable';
        case 417: return 'Expectation Failed';
        case 421: return 'Misdirected Request';
        case 422: return 'Unprocessable Entity';
        case 423: return 'Locked (WebDAV; RFC 4918)';
        case 424: return 'Failed Dependency (WebDAV; RFC 4918)';
        case 425: return 'Too Early (RFC 8470)';
        case 426: return 'Upgrade Required';
        case 428: return 'Precondition Required (RFC 6585)';
        case 429: return 'Too Many Requests (RFC 6585)';
        case 431: return 'Request Header Fields Too Large (RFC 6585)';
        case 451: return 'Unavailable For Legal Reasons (RFC 7725)';
        case 500: return 'Internal Server Error';
        case 501: return 'Not Implemented';
        case 502: return 'Bad Gateway';
        case 503: return 'Service Unavailable';
        case 504: return 'Gateway Timeout';
        case 505: return 'HTTP Version Not Supported';
        case 506: return 'Variant Also Negotiates (RFC 2295)';
        case 507: return 'Insufficient Storage (WebDAV; RFC 4918)';
        case 508: return 'Loop Detected (WebDAV; RFC 5842)';
        case 510: return 'Not Extended (RFC 2774)';
        case 511: return 'Network Authentication Required (RFC 6585)';
        default:
          return 'Application Error';
      }
    }

    private static fireValidationAlert = (e:any, title: string, applicationContext?: ApplicationContextType) => {
        if(!applicationContext){
            const errHtml=`Validation Error!<br><small>${e.message}</small>`;
            Swal.fire({title: title,html: errHtml,icon: 'error'}).then();
            return;
        }

        const { setValidationMessage } = applicationContext!;
        setValidationMessage(e.response.data);
    }
};


 