import {SubmitHandler, useFormContext} from "react-hook-form";
import {RequestActionModel} from "../../../../models/RequestActionModel";
import {nullableDatesAreSame} from "../../../../helper/DateUtilities";
import {pageRouteUrls} from "../../../../helper/PageRouteUrls";
import {actionService} from "../../../../service/ActionService";
import {ActionEditType} from "../ActionEditContext";
import {ApplicationContextType} from "../../../../contexts/ApplicationContext";

const submitActionEdit = (actionEditContext: ActionEditType, applicationContext?: ApplicationContextType) => {
    const {
        navigate,
        actionEditState: {
            requestId,
            setActionData,
            setBtnDisable,
            previousPageUrls
        },
        actionEditFormState: {formState: {defaultValues}}
    } = actionEditContext;

    const confirmFormDirty = (data: RequestActionModel) => {
        if(!nullableDatesAreSame(data.bringForwardDate, defaultValues?.bringForwardDate)
            || !nullableDatesAreSame(data.actionDate, defaultValues?.actionDate)
            || defaultValues?.fee != data.fee
            || defaultValues?.departmentToSearch != data.departmentToSearch
            || defaultValues?.departmentReceivedRecordsFrom != data.departmentReceivedRecordsFrom
            || defaultValues?.extensionDays != data.extensionDays
            || defaultValues?.responseReceived != data.responseReceived
            || defaultValues?.payment?.paymentMethodId != data.payment?.paymentMethodId
            || defaultValues?.payment?.amount !=  data.payment?.amount
            || defaultValues?.payment?.receiptNumber != data.payment?.receiptNumber)
            return true;

        return false;
    };

    const onSubmit: SubmitHandler<RequestActionModel> = async (data) => {
        if(data.id && !confirmFormDirty(data)) {
            navigate(pageRouteUrls.Action_Details(requestId, data.id), {state: {previousPageUrls: previousPageUrls}});
            return;
        }

        setBtnDisable(true);
        setActionData(data);
        const responseSave = await actionService.Save(data, applicationContext);
        if (responseSave) {
            navigate(pageRouteUrls.Action_Details(requestId, responseSave.id), {state: {previousPageUrls: previousPageUrls}});
        }
        setBtnDisable(false);
    };

    return onSubmit;
};

export default submitActionEdit;
