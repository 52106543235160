
import Layout from "../../component/Layout"
import { Row, Col, Card, Form, Button, Dropdown, InputGroup, Container, Spinner, Stack, Table, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {Alert, Checkbox, Grid, Snackbar, Step, StepLabel, Stepper, TextField, ThemeProvider} from "@mui/material";
import React, {useContext, useEffect, useMemo, useState} from "react";
import RequestModel from "../../models/RequestModel";
import { DateFormat } from "../../helper/Constants";
import { Format, calculateLifecycle, validateDate } from '../../helper/DateUtilities';
import { DeliveryMethodEnum, DisclosureTypeEnum, DispositionTypeEnum, ReleaseStatusEnum, RequestActionTypeEnum, RequestTypeEnum, RequesterSourceTypeEnum, getEnumLabel } from "../../helper/Enums";
import { RequestExceptionModel } from "../../models/RequestExceptionModel";
import { pageRouteUrls } from "../../helper/PageRouteUrls";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {  DropdownList } from "react-widgets";   
import { requestService } from "../../service/RequestService";
import { applicantService } from "../../service/ApplicantService"; 
import { ErrorMessage } from "@hookform/error-message"; 
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {LoadingSpinner} from "../../component/LoadingSpinner";
import dayjs, { Dayjs } from "dayjs";  
import ApplicantModel from "../../models/ApplicantModel";  
import { actionTypeService } from "../../service/ActionTypeService";
import RequestActionTypeModel from "../../models/RequestActionTypeModel";
import $ from 'jquery';
import ApplicationContext from "../../contexts/ApplicationContext";
import {
    blockInvalidNumberChar,
    blockInvalidWholeNumberChar,
    blockLessGreaterThanChar
} from "../../helper/ValidationUtilities";
import StepperTheme from "../../styles/themes/StepperTheme";

export interface Props {
}

const FOIPRequestEdit: React.FC<Props> = props => {
    const { id } = useParams();
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);  
    const [viewLifecycle, setViewLifecycle] = useState<number>(0);  
    const [showSearchDataNotFound,setShowSearchDataNotFound]=useState<boolean>(false); 
    const [showNoApplicantAttachedAlert,setShowNoApplicantAttachedAlert]=useState<boolean>(false); 
    const [suggestions,setSuggestions]=useState<ApplicantModel[]>([]);
    const [btnDisable,setBtnDisable]=useState<boolean>(false);
    const [requestTypeId,setRequestTypeId]=useState<RequestTypeEnum|null>(null);   
    const [dispositionTypeId,setDispositionTypeId]=useState<DispositionTypeEnum|null>(null);       
    const navigate = useNavigate();
    const [steps,setSteps]=useState<string[]>(['Initiated','Active','Closed']); 
    const { quApplicantId } = (location?.state ? location.state : 0) as { quApplicantId: number };
    const [request, setRequest] = useState<RequestModel|undefined>(undefined); 
    const { previousPageUrls } = (location?.state ? location.state : {previousPageUrls:[]}) as { previousPageUrls: string[] };

    const ActionTypeEnumLength = Object.keys(RequestActionTypeEnum).filter((v) => !isNaN(Number(v))).length;
    const breadcrumb: any =[];
    if(previousPageUrls && previousPageUrls[0] == pageRouteUrls.Search()) breadcrumb.push({ title: "Search", link: pageRouteUrls.Search()  });    
    breadcrumb.push({ title: `${id ? "Edit Request Details" : "New Request"}` }); 
 
    const [activeStep, setActiveStep] = React.useState(0);
    const [dueDateMinDate, setDueDateMinDate] = useState<Dayjs>(dayjs(new Date()));
    const [isOnHold, setIsOnHold] = useState<boolean>(false);
    const [allActionTypes, setAllActionTypes] = useState<RequestActionTypeModel[]>([]);
    const { control, register, handleSubmit, formState, reset,setValue ,getValues,clearErrors } = useForm<RequestModel>({  shouldUnregister: false,defaultValues: {} });
 
    const applicationContext = useContext(ApplicationContext);
    useEffect(() => {

        const fetchLookupData = async () => { 
            const allActionTypes = await actionTypeService.GetAll();
            setAllActionTypes(allActionTypes); 
        }
        const fetchData = async ()  => {  
            if (id) {
                const rqst = await requestService.GetById(parseInt(id));
                if (rqst) {
                    if((quApplicantId??0)>0 && rqst.applicantId!=quApplicantId)
                    {
                        const applicantData = await applicantService.GetById(quApplicantId);
                        if(applicantData){
                            rqst.applicantId=applicantData.id;
                            rqst.applicant=applicantData;
                        }
                    }
                    reset(rqst);
                    setRequest({ ...rqst });
                    setIsOnHold(rqst.dueDate==undefined );
                    setViewLifecycle(calculateLifecycle(rqst.dateReceived,rqst.completedDate));  
                }
            }
            else
            {
                setRequest(new RequestModel());
                reset(new RequestModel());
            } 
        } 
        setBtnDisable(true);
        setLoading(true);

        fetchLookupData();
        fetchData(); 
        setLoading(false);
        setBtnDisable(false);
    }, []);
    
    
     
    useEffect(() => {
        // const handleBeforeUnload = (e:any) => {
        //       e.preventDefault();
        //       console.log(formState.isDirty)
        //       e.returnValue = '';            
        // };      
        // window.addEventListener('beforeunload', handleBeforeUnload);      
        // return () => {
        //     window.removeEventListener('beforeunload', handleBeforeUnload);
        // };
        
        //to prevent navigation 
      // if (formState.isDirty) {
       //   window.onbeforeunload = () => true
       //   window.onunload = () => true 
      // } else {
      //   window.onbeforeunload = null;
      // }
    //    window.addEventListener("beforeunload", onUnload);
 
    }, []);
     

    useEffect(() => {
        if (request) { 
            setRequestTypeId(request.requestTypeId);
            setDispositionTypeId(request.dispositionTypeId);
            setDueDateMinDate(dayjs(request.dateReceived));  
           reset(request);
        } 
    }, [request]);
    
    useEffect(() => {  
            let stepNumber=0;
            if (request?.completedDate) 
                stepNumber= 2;  
            else if (viewLifecycle < 1)
                stepNumber= 0;
            else
                stepNumber= 1;
            setActiveStep(stepNumber); 

    }, [viewLifecycle]);
     
    useEffect(() => {   
        if(request)
        {
            setSteps( ['Initiated',(request.dueDate? 'Active':'On Hold'),'Closed']);
        }
    },[request?.dueDate] ); 

    useEffect(() => {
        if (quApplicantId > 0) {
            const fetchApplicantData = async (id: number) => {
                setSearchLoading(true);
                const applicantData = await applicantService.GetById(id);
                if(!request) {
                    setRequest(new RequestModel()); 
                    reset(request);
                }
                await searchChangeValue(applicantData); 
                setSearchLoading(false);
            }         
            fetchApplicantData(quApplicantId);
        }
    }, [quApplicantId]);

    const searchChangeValue = async (applicantData:ApplicantModel|undefined ) => {   
        setSearchLoading(true); 
        if(applicantData )
        {
            let rqst=request??new RequestModel(); 
            rqst.applicantId=applicantData.id;
            rqst.applicant=applicantData;

            setValue("applicantId", rqst.applicantId); 
            setValue("applicant", rqst.applicant);
            setShowNoApplicantAttachedAlert(false);
            setRequest({...rqst});
        }
        resetSearchDDL();
        setSearchLoading(false);
    }; 
    
    const onSuggestionsFetchRequested = ( e:any ) => {  
        const fetchSearchData = async () => { 
            const rqst =await applicantService.Search(e.searchTerm); 
            setSuggestions(rqst);            
            if(rqst && rqst.length>0) //to hide the search list in case if no data to avoid showing the default No item found message  
                $(".rw-popup-container").show();
            else
                $(".rw-popup-container").hide();
            setShowSearchDataNotFound(!suggestions || suggestions.length==0);

            if(!e.searchTerm)
            { 
                resetSearchDDL();
            }

        } 
        fetchSearchData();
    };

    const resetSearchDDL=()=>
    {
        setSuggestions([]);
        const timeout = setTimeout(() => {
            $(".rw-dropdown-list-value").html('');                                                    
        }, 10) 
        return () => clearTimeout(timeout)
    }

    const renderActionRow = (index: Number, type: RequestActionTypeModel) => {
        
        if (request && request.requestActions && request.requestActions?.some(a => a.requestActionTypeId == type.id)) {
            const action = request.requestActions.find(a => a.requestActionTypeId == type.id);
            return (
                <div className="row mb-3">
                    <div className="col-sm-2">
                        <Checkbox readOnly checked={true} />
                    </div>
                    <div className="col-sm-6" >
                        <Link to={pageRouteUrls.Action_Details(id, action?.id)} 
                            state={{ previousPageUrls: [...previousPageUrls,pageRouteUrls.Request_Edit(id)] }} >
                            {type?.name}
                        </Link>
                    </div>
                    <div className="col-sm-4" >
                        {(action?.actionDate) ? (
                            <Form.Control plaintext readOnly value={Format(action.actionDate, DateFormat)} />
                        ) :
                            (<></>)
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="row mb-3">
                    <div className="col-sm-2">
                        <Checkbox readOnly checked={false} />
                    </div>
                    <div className="col-sm-6" >
                        {type?.name}
                    </div>
                    <div className="col-sm-4" > -- </div>
                </div>
            );
        }
    }
    const renderDispositionTypeSelect= useMemo(() => {     
            switch(Number(requestTypeId)) {
                case Number(RequestTypeEnum.Correction):
                    return (
                    <>  
                        <select {...register("dispositionTypeId")} className="form-select"
                            onChange={(a)=>setDispositionTypeId(a.target.value?Number(a.target.value):null)}
                        >
                            <option key="-1" value="">- Select - </option> 
                            {[DispositionTypeEnum.Corrected,DispositionTypeEnum.NotCorrected,DispositionTypeEnum.Internal$IncorrectRequests]
                                    .map((key, index) =>
                                    ( 
                                        <option key={key} value={Number(key)} >
                                            {getEnumLabel(DispositionTypeEnum[Number(key)])}
                                        </option>
                                    ))}
                                
                            </select> 
                        </>);
                default:
                return ( 
                    <>  
                        <select {...register("dispositionTypeId")} className="form-select"
                            onChange={(a)=>setDispositionTypeId(a.target.value?Number(a.target.value):null)}
                        >
                            <option key="-1" value="">- Select - </option>  
                            {Object.keys(DispositionTypeEnum).filter((v) => !isNaN(Number(v)))
                                .filter(item=>
                                        ![DispositionTypeEnum.Corrected,DispositionTypeEnum.NotCorrected]
                                        .includes(Number(item))                                                                    
                                    )                                                            
                                .map((key, index) =>
                                (
                                    <option key={key} value={Number(key)} >
                                        {getEnumLabel(DispositionTypeEnum[Number(key)])}
                                    </option>
                                ))}  
                        
                        </select> 
                    </>) ;
            } 
        }, [requestTypeId]);
    
    const renderRequestHeaderInfo=() => {      
                return (
                    <Row className="mb-3">
                        <Form.Group as={Col} >
                            <Form.Label>File Number</Form.Label>
                            <Form.Control plaintext readOnly value={request?.fileNumber} />
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Date Received</Form.Label>
                            <Controller
                                control={control}
                                name='dateReceived'
                                rules={{
                                    required: 'The Date Received is required.',
                                    validate: (date) => validateDate(date, "Date Received", undefined, undefined, dayjs(new Date()), 'The Date Received cannot be a future date.')
                                }}
                                render={({ field }) => (
                                    <DatePicker autoFocus
                                        maxDate={dayjs()}
                                        views={['year', 'month', 'day']}
                                        onChange={(date) => {
                                            let cDate = date && date.isValid() ? date : dayjs(new Date());
                                            //set Min DueDate validation boundary to 'Date Received' if it valid else to today date
                                            setDueDateMinDate(cDate); 
                                            
                                            setViewLifecycle(calculateLifecycle(new Date(cDate.toDate()), getValues().completedDate));
                                            if(request)
                                            {
                                                //Calculate dueDate for new request only 
                                                if (!request.requestActions || request.requestActions.length==0) {
                                                    if (date && date.isValid()) { // valid date and not null 

                                                        request.dueDate = new Date(date.toDate());
                                                        request?.dueDate.setDate(request.dueDate.getDate() + 30);
                                                    }
                                                    setValue("dueDate", request.dueDate); 
                                                }
                                            }
                                            clearErrors();
                                            field.onChange(date);
                                        }}
                                        value={field.value ? dayjs(field.value) : null}
                                        slotProps={{
                                            textField: {
                                                placeholder: "Enter date received",
                                                className: "form-control requiredDate"
                                            }
                                        }}
                                    />
                                )} />
                            <ErrorMessage errors={formState.errors} name="dateReceived" render={({ message }) => <span className="error-message" >{message}</span>} />

                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Due Date</Form.Label>
                            {isOnHold ?
                                (<></>) :
                                (<>
                                    <Controller
                                        control={control}
                                        name='dueDate'
                                        rules={{
                                            required: 'The Due Date is required.',
                                            validate: (date) => validateDate(date, "Due Date", dueDateMinDate, `Due Date cannot be prior to the Date Received.`, undefined, undefined)
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <DatePicker
                                                    views={['year', 'month', 'day']}
                                                    slotProps={{
                                                        textField: {
                                                            placeholder: "Enter due date",
                                                            className: "form-control requiredDate"
                                                        }
                                                    }}
                                                    onChange={(date) => field.onChange(date)}
                                                    value={field.value ? dayjs(field.value) : null}
                                                />
                                            </>
                                        )}
                                    />
                                    <ErrorMessage errors={formState.errors} name="dueDate" render={({ message }) => <span className="error-message" >{message}</span>} />
                                </>
                                )}
                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Request Lifecycle</Form.Label>
                            <Form.Control plaintext readOnly value={`${viewLifecycle>=0?viewLifecycle:'--'} days`} />

                        </Form.Group>

                        <Form.Group as={Col} >
                            <Form.Label>Request Status</Form.Label>
                            <Form.Control plaintext readOnly value={request?.requestStatus} />
                        </Form.Group>
                    </Row>
                ); 
        }      
    const renderApplicantInfo=() => {    
            if(request && request.applicantId > 0)  
                return (
                <> 
                    <Row>
                        <Col> 
                            <Form.Label>First Name</Form.Label> 
                            <Form.Control plaintext readOnly value={request.applicant.firstName} /> 
                        </Col> 
                        <Col> 
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control plaintext readOnly value={request.applicant.lastName} /> 
                        </Col>
                        <Col> 
                            <Form.Label>Contact Details</Form.Label>
                            <Form.Control plaintext readOnly value={request.applicant.contactDetails} /> 
                        </Col> 
                    </Row>
                    <Row> 
                        <Col className="textAlign-end">
                            {(request?.applicantId) ? (
                                <Link to={pageRouteUrls.Applicant_Details(request.applicantId)} 
                                    state={{sourceRequestId: id ,
                                        previousPageUrls: [...previousPageUrls,(id && Number.parseInt(id)>0? pageRouteUrls.Request_Edit(id):pageRouteUrls.Request_Add())]
                                    }}>Click Here for more applicant details</Link>
                            ) : (<></>)}
                        </Col>
                    </Row>
                </>
                ); 
        }      

    const onSubmit: SubmitHandler<RequestModel> = async (data) => {
        setBtnDisable(true);  
        // validate data 
        if (!(data.applicantId > 0)) { 
            setShowNoApplicantAttachedAlert(true); 
            setBtnDisable(false);
            return;
        } 
        setRequest(data);
        const responceSave = await requestService.Save(data, applicationContext);
        if (responceSave) { 
            id && Number.parseInt(id)>0 ?
                    navigate(pageRouteUrls.Request_Details(responceSave.id), { state: { previousPageUrls: previousPageUrls } }) :
                    navigate(pageRouteUrls.Home());
            
        } 
        setBtnDisable(false);
    }
      
    const renderActionSummaryGrid=()=>{
        return (
            <Row> 
                                        <Col>  
                                            <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                                { allActionTypes.slice(0,5).map((aType,index) => 
                                                
                                                    <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                                        { renderActionRow(index,aType) }
                                                    </Grid>   
                                                )} 
                                            </Grid> 
                                        </Col> 
                                        <Col> 
                                            <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            { allActionTypes.slice(5,10).map((aType,index) => 
                                                
                                                <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                                    { renderActionRow(index,aType) }
                                                </Grid>   
                                            )}  
                                            </Grid> 
                                        </Col>  
                                        <Col> 
                                            <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            { allActionTypes.slice(10,15).map((aType,index) => 
                                                
                                                <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                                    { renderActionRow(index,aType) }
                                                </Grid>   
                                            )}  
                                            </Grid> 
                                        </Col>  
                                        <Col> 
                                            <Grid container direction={'column'} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                            { allActionTypes.slice(15,ActionTypeEnumLength).map((aType,index) => 
                                                
                                                <Grid item xs={3}  key={`requestActionRow${index}`}>  
                                                    { renderActionRow(index,aType) }
                                                </Grid>   
                                            )}  
                                            </Grid> 
                                        </Col>  

    
                                    </Row>
        )
    }

    const renderExceptionsTable=(requestExceptions:RequestExceptionModel[])=> {
        return (
            <Table className="ActiveHomeTable HomeTable" bordered hover size="sm">
                <thead>
                    <tr className="bg-primary"> 
                        <th>Exception Code</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {(requestExceptions && requestExceptions.length > 0) ?
                    (
                        requestExceptions.map((exception: RequestExceptionModel, index: any) => {
                            return (
                                <tr key={`requestExceptions${index}`}> 
                                    <td>{exception.exceptionType?.code}</td>
                                    <td className="tableExceptionDescriptionCell">{exception.exceptionType?.name}</td>
                                </tr>
                            )
                            })) :
                            (
                                <tr>
                                    <td colSpan={3}> No Data found</td>
                                </tr>
                            )}
                </tbody>
            </Table>
        )
    }
    const renderRequestDetails=()=>{
        return (
                                    <Row className="mb-3">
                                        <Col>
                                            <Card className="mb-3">
                                                <Card.Header><strong>Request Processing Details</strong></Card.Header>
                                                <Card.Body>

                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label sm={4}>Request Summary</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <textarea  cols={45} rows={4}
                                                                placeholder="Enter Request Summary"
                                                                className="form-control required"  
                                                                {...register("requestSummary",
                                                                {
                                                                    required: 'The Request Summary is required.',
                                                                    maxLength: { value: 256, message: "The Request Summary cannot be more than 256 characters." }
                                                                })}
                                                                 onKeyDown={blockLessGreaterThanChar}
                                                                />
                                                            <ErrorMessage errors={formState.errors} name="requestSummary" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label>Request Details</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <textarea  cols={45} rows={5}
                                                                placeholder="Enter Request Details" 
                                                                className="form-control required" 
                                                                {...register("requestText", 
                                                                { 
                                                                    required: 'The Request Details is required.',
                                                                    maxLength: { value: 4000, message: "The Request Details cannot be more than 4000 characters." }
                                                                })}
                                                                onKeyDown={blockLessGreaterThanChar}
                                                            />
                                                            <ErrorMessage errors={formState.errors} name="requestText" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label>Request Type</Form.Label> 
                                                        </div>
                                                        <div className="col-sm-8" > 
                                                            {(id && Number.parseInt(id)>0)?
                                                            (
                                                                <Form.Control plaintext readOnly value={request?.requestTypeName} />):
                                                            (<>
                                                                <select {...register("requestTypeId", { required: 'The Request Type is required.' })}
                                                                    
                                                                    onChange={(a)=>setRequestTypeId(a.target.value?Number(a.target.value):null)}
                                                                    className="form-select required">
                                                                    <option key="-1" value="">- Select - </option>
                                                                    {Object.keys(RequestTypeEnum).filter((v) => !isNaN(Number(v))).map((key, index) =>
                                                                    (
                                                                        <option key={key} value={Number(key)}>
                                                                            {getEnumLabel(RequestTypeEnum[Number(key)])}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <ErrorMessage errors={formState.errors} name="requestTypeId" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                            </>)
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                    {(requestTypeId== RequestTypeEnum.General)?
                                                        (
                                                            <div className="row mb-3">
                                                                <div className="col-sm-4" >
                                                                    <Form.Label>Request Source</Form.Label>
                                                                </div>
                                                                <div className="col-sm-8" >
                                                                    <select {...register("requesterSourceTypeId", { required: 'The Request Source is required.' })}
                                                                        
                                                                        className="form-select required">
                                                                        <option key="-1" value="">- Select - </option>
                                                                        {Object.keys(RequesterSourceTypeEnum).filter((v) => !isNaN(Number(v))).map((key, index) =>
                                                                        (
                                                                            <option key={key} value={Number(key)}>
                                                                                {getEnumLabel(RequesterSourceTypeEnum[Number(key)])}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <ErrorMessage errors={formState.errors} name="requesterSourceTypeId" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                                </div>
                                                            </div> 
                                                        ):(<></>)
                                                    }

                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label sm={4}>Document Location</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <Form.Control type="text" {...register("sharepointUrl",
                                                            {
                                                                // pattern: {
                                                                //     value: namesValidationExp ,
                                                                //     message: 'Please enter a valid Document Location.'
                                                                //   }, 
                                                                //   maxLength: { value: 35, message: "Please enter a valid Document Location." } 
                                                            })} 
                                                            
                                                            placeholder="Enter Document Location" />
                                                            <ErrorMessage errors={formState.errors} name="sharepointUrl" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div>
                                                    
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="mb-3">
                                                <Card.Header><strong>Request Response Details</strong></Card.Header>
                                                <Card.Body>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label>Record Disclosed</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <select {...register("disclosureTypeId")} 
                                                                
                                                                className="form-select">
                                                                <option key="-1" value="">- Select - </option>
                                                                {Object.keys(DisclosureTypeEnum).filter((v) => !isNaN(Number(v))).map((key, index) =>
                                                                (
                                                                    <option key={key} value={Number(key)}>
                                                                        {getEnumLabel(DisclosureTypeEnum[Number(key)])}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <ErrorMessage errors={formState.errors} name="disclosureTypeId" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label>Disposition</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >  
                                                            {renderDispositionTypeSelect} 
                                                            <ErrorMessage errors={formState.errors} name="dispositionTypeId" render={({ message }) => <span className="error-message" >{message}</span>} />
        
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label>Release Status</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <select {...register("releaseStatusTypeId")} 
                                                                
                                                                className="form-select">
                                                                <option key="-1" value="">- Select - </option>
                                                                {Object.keys(ReleaseStatusEnum).filter((v) => !isNaN(Number(v))).map((key, index) =>
                                                                (
                                                                    <option key={key} value={Number(key)}>
                                                                        {getEnumLabel(ReleaseStatusEnum[Number(key)])}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <ErrorMessage errors={formState.errors} name="releaseStatusTypeId" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label>Method of Delivery</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <select {...register("deliveryMethodTypeId")} 
                                                                
                                                                className="form-select">
                                                                <option key="-1" value="">- Select - </option>
                                                                {Object.keys(DeliveryMethodEnum).filter((v) => !isNaN(Number(v))).map((key, index) =>
                                                                (
                                                                    <option key={key} value={Number(key)}>
                                                                        {getEnumLabel(DeliveryMethodEnum[Number(key)])}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <ErrorMessage errors={formState.errors} name="deliveryMethodTypeId" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>

                                            <Card className="mb-3">
                                                <Card.Header><strong>Completed Request Details</strong></Card.Header>
                                                <Card.Body>
                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label> Number of Pages</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <Form.Control type="number"
                                                                {...register("numberOfPages", 
                                                                { 
                                                                    max: { value: 999999, message: "Number of pages must be less than 1000000." },
                                                                    valueAsNumber: true,   
                                                                })}
                                                                onKeyDown={blockInvalidWholeNumberChar}
                                                                placeholder="Enter Number Of Pages" />
                                                            <ErrorMessage errors={formState.errors} name="numberOfPages" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div> 

                                                    <div className="row mb-3">
                                                        <div className="col-sm-4" >
                                                            <Form.Label>Completed Date</Form.Label>
                                                        </div>
                                                        <div className="col-sm-8" >
                                                            <Controller
                                                                control={control}
                                                                name='completedDate'
                                                                rules={{ 
                                                                    validate: (date) => validateDate(date, "Completed Date",dueDateMinDate,`The Completed Date cannot be earlier than the Date Received.`, dayjs(new Date()), 'The Completed Date cannot be a future date.'),
                                                                    required:Number(dispositionTypeId)>0  ? 'The Completed Date is required for Requests with Dispositions.':false ,
                                                                }}
                                                                render={({ field }) => ( 
                                                                        <DatePicker 
                                                                            maxDate={dayjs()}
                                                                            views={['year', 'month', 'day']}
                                                                            slotProps={{ textField : { 
                                                                                placeholder:'Enter completed Date', 
                                                                                className: "form-control" 
                                                                            } }} 
                                                                            
                                                                            onChange={(date) =>{
                                                                                let cDate = date && date.isValid() ? new Date(date.toDate()) : null;
                                                                                setViewLifecycle(calculateLifecycle(getValues().dateReceived,cDate));
                                                                                field.onChange(date);                                                                                
                                                                            }}
                                                                            value={field.value?dayjs(field.value):null} 
                                                                        /> 
                                                                )} />
                                                            <ErrorMessage errors={formState.errors} name="completedDate" render={({ message }) => <span className="error-message" >{message}</span>} />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
        )
    }
    return (
        <Layout breadcrumList={breadcrumb}>
            { !loading && request ? 
            (
                <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">Request Progress</strong>
                                    </Col>

                                    <Col className="textAlign-end"> 
                                        <Button variant="primary" type="submit" disabled={btnDisable} >Save</Button>
                                        <Link to={id ? pageRouteUrls.Request_Details(id) : ( previousPageUrls[0] == pageRouteUrls.Search()?pageRouteUrls.Search():pageRouteUrls.Home())} 
                                            state={{ previousPageUrls: previousPageUrls }} className="btn btn-dark">
                                                Cancel
                                        </Link> 
 
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Container>
                                    <Row>
                                        <Col lg="12">
                                            <ThemeProvider theme={StepperTheme}>
                                                <Stepper activeStep={activeStep}>
                                                    {steps.map((label, index) => {
                                                        const stepProps: { completed?: boolean } = {};
                                                        const labelProps: {
                                                            optional?: React.ReactNode;
                                                        } = {};
                                                        return (
                                                            <Step key={label} {...stepProps}>
                                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                                            </Step>
                                                        );
                                                    })}
                                                </Stepper>
                                            </ThemeProvider>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3"></Row>
                                    {renderRequestHeaderInfo()} 
                                </Container>
                            </Card.Body>
                        </Card>

                        <Card className="mb-3">
                            <Card.Header>
                                <Row>
                                    <Col className="fs-5">
                                        <strong className="mx-2 colorDark">Applicant</strong>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                {searchLoading ? (
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : (

                                <Container> 
                                    <Row> 
                                        <Col  className=" mb-3" > 
                                                <Form.Label >Search Applicant</Form.Label> 
                                                <DropdownList 
                                                    id="searchDrplst"
                                                    name="searchDrplst"
                                                    placeholder="Enter Applicant Name"
                                                    data={suggestions}
                                                    onSelect={value => searchChangeValue(value)}  
                                                    onBlur={value => resetSearchDDL()}
                                                    onKeyDown={blockLessGreaterThanChar}
                                                    dataKey='id'
                                                    textField='firstName'
                                                    filter={() => true}
                                                    busy={searchLoading}    
                                                    busySpinner={<span className="fas fa-sync fa-spin" />}        
                                                    onSearch={(searchTerm) => onSuggestionsFetchRequested({ searchTerm })} 
                                                    renderListItem={({ item }) => (
                                                            <span>
                                                                <strong>{item.firstName + ' ' + item.lastName}</strong>
                                                                <span> | </span>
                                                                <span>{item.contactDetails}</span> 
                                                            </span>
                                                        )}
                                                        />
                                                {( showSearchDataNotFound )?
                                                (
                                                <Alert key={'warning'}  severity="warning">
                                                    No match found. Please Add a new applicant.
                                                </Alert>  ):(<></>)
                                                }
                                                {( showNoApplicantAttachedAlert )?
                                                (
                                                <Alert key={'error'}  severity="error">
                                                    Please select an existing applicant or add a new applicant
                                                </Alert>  ):(<></>)
                                                } 
 
                                        </Col>
                                        <Col className="textAlign-end"> 
                                                <Link to={pageRouteUrls.Applicant_Add()} 
                                                    state={{ sourceRequestId: id && Number.parseInt(id) ? id : 0, 
                                                    previousPageUrls: [...previousPageUrls, (id ? pageRouteUrls.Request_Edit(id) : pageRouteUrls.Request_Add())]
                                                    }} className="btn btn-success" >Add Applicant</Link>

                                                     
                                        </Col>
                                    </Row>
                                    {renderApplicantInfo()}   
                                </Container>                                
                                )}
                            </Card.Body>
                        </Card>

                        {  (request.applicantId && request.applicantId>0) as boolean? (
                            <Card className="mb-3">
                                <Card.Header>
                                    <Row>
                                        <Col className="fs-5">
                                            <strong className="mx-2 colorDark">Request Details</strong>
                                        </Col>

                                        <Col className="textAlign-end">
                                            <Button variant="primary" type="submit" disabled={btnDisable} >Save</Button>
                                            <Link to={id ? pageRouteUrls.Request_Details(id) : ( previousPageUrls[0] == pageRouteUrls.Search()?pageRouteUrls.Search():pageRouteUrls.Home())}
                                                  state={{ previousPageUrls: previousPageUrls }} className="btn btn-dark">
                                                Cancel
                                            </Link>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {renderRequestDetails()}
                                     
                                </Card.Body>
                            </Card>
                        ):(<></>)}
                        {request.id > 0 ?
                        (
                            <>
                            <Card className="mb-3">
                                <Card.Header>
                                    <Row>
                                        <Col className="fs-5">
                                            <strong className="mx-2 colorDark">Action Summary</strong>
                                        </Col>
                                                <Col className="textAlign-end">
                                                    <Link to={pageRouteUrls.Action_Add(id)} 
                                                        state={{ previousPageUrls: [...previousPageUrls,pageRouteUrls.Request_Edit(id)] }} 
                                                        className="btn btn-success">New Action</Link>
                                                </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body> 
                                    {renderActionSummaryGrid()}   
                                </Card.Body>
                            </Card>
                       
                            <Card className="mb-3">
                                <Card.Header>
                                    <Row>
                                        <Col className="fs-5">
                                            <strong className="mx-2 colorDark">Exceptions Applied</strong>
                                        </Col> 
                                                <Col className="textAlign-end">                                                
                                                    <Link to={pageRouteUrls.MngExceptions(id)} 
                                                        state={{ previousPageUrls:[...previousPageUrls,pageRouteUrls.Request_Edit(id)] }} 
                                                        className="btn btn-success">Manage Exception</Link>
                                                </Col>  
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {renderExceptionsTable(request.requestExceptions)}  
                                </Card.Body>
                            </Card> 
                            </>
                          ) : (
                            <></>
                        )
                    }
                    </LocalizationProvider>

                </Form>
            ):
            (
                <LoadingSpinner />
            )
            }
        </Layout>
    )
}
export default FOIPRequestEdit 

