import { PaymentModel } from "./PaymentModel";
import RequestActionTypeModel from "./RequestActionTypeModel";

export class RequestActionModel {
    id:number= 0;
    requestActionTypeId:number= 0;
    actionType:RequestActionTypeModel|null = null;
    actionDate:Date = new Date();
    departmentToSearch: string|null= null;
    departmentReceivedRecordsFrom : string|null= null;
    bringForwardDate:Date|null= null;
    extensionDays:number|null= null;
    payment:PaymentModel|null= null;
    requestFileNumber:string|null=null;
    fee:number|null= null;
    responseReceived:boolean= false;
    requestId:number= 0;
}
