import dayjs, {Dayjs} from "dayjs";
import {useState} from "react";
import { Card, Col, Form, Row, Table,Button } from "react-bootstrap"; 
import {SubmitHandler, useForm, Controller} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom"; 
import SearchParamModel from "../../models/SearchParamModel";
import SearchWrapperModel from "../../models/SearchWrapperModel";
import { DataGrid,gridClasses, GridRowsProp } from "@mui/x-data-grid"; 
import { requestService } from "../../service/RequestService";
import { ErrorMessage } from "@hookform/error-message";
import { RequestTypeEnum, SearchCriteriaEnum, getEnumLabel } from "../../helper/Enums"; 
import { FormControlLabel, Radio, RadioGroup, alpha, styled } from "@mui/material"; 
import { validateDate2 } from "../../helper/DateUtilities";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {blockLessGreaterThanChar} from "../../helper/ValidationUtilities";

function useSearch() {  
    const [btnDisable,setBtnDisable]=useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const {previousPageUrls} = (location?.state ? location.state : []) as { previousPageUrls: string[] }; 
    const { quSearchWrapper } = (location?.state ? location.state : new SearchWrapperModel()) as { quSearchWrapper: SearchWrapperModel|null };
    const [rows,setRows]=useState<GridRowsProp>( quSearchWrapper?.rows??[]);
    const [searchParams, SetSearchParams] = useState<SearchParamModel>( quSearchWrapper?.searchParams?? new SearchParamModel());   
    const searchFormState = useForm<SearchParamModel>({ shouldUnregister: false, defaultValues: quSearchWrapper?.searchParams?? new SearchParamModel()});  
    const navigate = useNavigate();
 
    const onSearchSubmit: SubmitHandler<SearchParamModel> = async (data) => {
        // validate data 
        setLoading(true); 
        SetSearchParams(searchParams=>data);   
        const rqst =await requestService.SearchBy(data);    
        setRows(rqst);
        setLoading(false);  
      }
    
    const { setValue} = searchFormState;
    
    const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
        [`& .${gridClasses.row}.even`]: {
          backgroundColor: alpha(theme.palette.primary.main,0.1 +theme.palette.action.selectedOpacity +theme.palette.action.hoverOpacity,), 
          '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.grey[300],
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey[400],
            '&:hover, &.Mui-hovered': {
              backgroundColor: theme.palette.grey[300],
              '@media (hover: none)': {
                backgroundColor: theme.palette.grey[400],
              },
            },
          },
      
        }, 
      
        [`& .${gridClasses.row}.odd`]: { 
          '&:hover, &.Mui-hovered': {
            backgroundColor:  theme.palette.grey[300], 
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          }, 
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey[400], 
            '&:hover, &.Mui-hovered': {
              backgroundColor:  theme.palette.grey[300], 
              '@media (hover: none)': {
                backgroundColor:  theme.palette.grey[400], 
              },
            },
      
          },
        }, 
      
      }));
    const renderApplicantSearchCriteria=()=>{
        return (
            <div className="row mb-3"> 
                <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1" >
                    <label><strong>Search By Applicant</strong></label>
                </div>
                <div className="col-sm-6" > 
                  <Form.Control type="text" {...searchFormState.register("byApplicantName", 
                  {  
                    pattern: {
                      value: /^[A-Za-z0-9\.\-\'\s@.]+$/, 
                      message: 'Please enter a valid Applicant Name for the search.'
                    },
                    required: 'Please enter the Applicant search criteria.', 
                    maxLength: { value: 71, message: "Please enter a valid Applicant Name for the search." } 
                  })} onKeyDown={blockLessGreaterThanChar} className="required"  placeholder="Enter Applicant Name" />
                  <ErrorMessage errors={searchFormState.formState.errors} name="byApplicantName" render={({ message }) => <span className="error-message" >{message}</span>}/>
                </div> 
            </div>
        )
      }
    const renderDateRangeSearchCriteria=()=>{
        return (
           <>
              <div className="row mb-3"> 
                  <div className="col-sm-3 col-lg-2" >
                  </div>
                  <div className="col-sm-8" >
                    <Controller
                        control={searchFormState.control}
                        name='byDateType'
                        rules={{
                          required:'Please choose the Date Type for the search.',
                        }}
                        render={({ field }) => ( 
    
                      <RadioGroup 
                        defaultValue="1"
                        name="radio-buttons-group"
                        onChange={(date) =>{ 
                          field.onChange(date);
                          }}
                          value={field.value} 
                          row
                      >
                        <FormControlLabel value={1} control={<Radio />} label="Received Date" />
                        <FormControlLabel value={2} control={<Radio />} label="Due Date"  />
                        <FormControlLabel value={3} control={<Radio />} label="Closed Date"  />
                      </RadioGroup>
    
                    )}/> 
                    <ErrorMessage errors={searchFormState.formState.errors} name="byDateType" render={({ message }) => <span className="error-message" >{message}</span>} />
    
                  </div>   
              </div>
              <div className="row mb-3"> 
                  <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1" >
                      <label><strong>Search By Date Range</strong></label>
                  </div>
                  <div className="col-sm-3" >
                    
                    <Controller
                        control={searchFormState.control}
                        name='byDateFrom'
                        rules={{
                          required:'Please enter both From and To Dates for the search.',
                          validate: (date) => validateDate2(date, "Date From",undefined,undefined,searchParams.byDateTo,'The Date From cannot be after Date To.')
                        }}
                        render={({ field }) => (
                          <DatePicker  
                              views={['year', 'month', 'day']} 
                              slotProps={{ textField : { 
                                  placeholder:"Enter Date From", 
                                  className: "form-control requiredDate" 
                              } }}
                              onChange={(date) =>{ 
                                SetSearchParams({...searchParams,['byDateFrom']:date?new Date(date?.toDate()):undefined})  
                                field.onChange(date)
                              }}
                              value={field.value?dayjs(field.value):null}
                            />
                        )}/>
                        <ErrorMessage errors={searchFormState.formState.errors} name="byDateFrom" render={({ message }) => <span className="error-message" >{message}</span>} />
    
                  </div> 
                  <div className="col-sm-3" >
                    <Controller
                        control={searchFormState.control}
                        name='byDateTo'
                        rules={{
                          required:'Please enter both From and To Dates for the search.',
                          validate: (date) => validateDate2(date, "Date From",searchParams.byDateFrom,'The Date To cannot be earlier than Date From.',undefined,undefined)
                        }}
                        render={({ field }) => (
                          <DatePicker  
                              views={['year', 'month', 'day']}
                              slotProps={{ textField : { 
                                  placeholder:"Enter Date To", 
                                  className: "form-control requiredDate" 
                              } }} 
                              onChange={(date) => {
                                SetSearchParams({...searchParams,['byDateTo']:date?new Date(date?.toDate()):undefined}) 
                                field.onChange(date)
                              }}
                              value={field.value?dayjs(field.value):null}
                          />
                        )}/> 
                        <ErrorMessage errors={searchFormState.formState.errors} name="byDateTo" render={({ message }) => <span className="error-message" >{message}</span>} />
    
                  </div> 
              </div>
           </>
        )
      }
    const renderRequestTypeSearchCriteria=()=>{
        return (
                  <div className="row mb-3"> 
                      <div className="col-sm-3  col-lg-2 ps-lg-4 pt-lg-1" >
                          <label><strong>Search By RequestType</strong></label>
                      </div>
                      <div className="col-sm-6" > 
    
                        <select {...searchFormState.register("byRequestTypeId", { required: 'The Request Type is required.' })} className="form-select required">
                            <option key="RequestTypeEnum-1" value="">- Select - </option>
                            {Object.keys(RequestTypeEnum).filter((v) => !isNaN(Number(v))).map((key, index) =>
                            (
                                <option key={"RequestTypeEnum"+key} value={Number(key)}>
                                    {getEnumLabel(RequestTypeEnum[Number(key)])}
                                </option>
                            ))}
                        </select>
                        <ErrorMessage errors={searchFormState.formState.errors} name="byRequestTypeId" render={({ message }) => <span className="error-message" >{message}</span>} />
    
                      </div> 
                  </div>
        )
      }
    const renderFileNumberSearchCriteria=()=>{
        return (
          <div className="row mb-3"> 
            <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1" >
                <label><strong>Search By File Number</strong></label>
            </div>
            <div className="col-sm-6" >  
              <Form.Control type="text" {...searchFormState.register("byFileNumber", 
              {  
                pattern: {
                  value: /^[A-Za-z0-9\-]+$/, 
                  message: 'Invalid File Number. Please enter a valid File number for the search.'
                },
                maxLength: { value: 11, message: "Invalid File Number. Please enter a valid File number for the search." } ,
                required: 'Please enter the File number search criteria.',  
              })} onKeyDown={blockLessGreaterThanChar} className="required"  placeholder="Enter File Number" />
              <ErrorMessage errors={searchFormState.formState.errors} name="byFileNumber" render={({ message }) => <span className="error-message" >{message}</span>}/>
            </div> 
          </div>    
        )
      }
    const renderKeywordSearchCriteria=()=>{
        return (
          <div className="row mb-3"> 
            <div className="col-sm-3 col-lg-2 ps-lg-4 pt-lg-1" >
                <label><strong>Search By Keyword</strong></label>
            </div>
            <div className="col-sm-6" > 
              <Form.Control type="text" {...searchFormState.register("byKeywordTxt", 
                {  
                  required: 'Please enter the Keyword for the search.',  
                })}
                onKeyDown={blockLessGreaterThanChar} className="required"  placeholder="Enter Keyword" />
                <ErrorMessage errors={searchFormState.formState.errors} name="byKeywordTxt" render={({ message }) => <span className="error-message" >{message}</span>}/>
            </div> 
          </div>
        )
      }
    const renderSearchCriteria=(searchType:SearchCriteriaEnum|undefined)=>{
        return (searchType==SearchCriteriaEnum.Applicant ) ? (
          <>{renderApplicantSearchCriteria()}</>
          ) : (searchType==SearchCriteriaEnum.ActiveRequests )? (
            <></>
          ) : (searchType==SearchCriteriaEnum.DateRange )? (
            <>{renderDateRangeSearchCriteria()} </> 
          ) : (searchType==SearchCriteriaEnum.RequestType)? (
            <>{renderRequestTypeSearchCriteria()} </>
          ) : (searchType==SearchCriteriaEnum.CompletedRequests)? (
              <></>
          ) : (searchType==SearchCriteriaEnum.FileNumber)? (
            <>{renderFileNumberSearchCriteria()}</> 
          ) : (searchType==SearchCriteriaEnum.Keyword )? (
            <>{renderKeywordSearchCriteria()}</> 
          ):(<></>)
        }
    
    const searchState = {
        btnDisable, setBtnDisable,
        loading, setLoading,
        rows,setRows,
        searchParams, SetSearchParams,
        previousPageUrls
    };
    return {
        searchState,  
        onSearchSubmit,
        renderSearchCriteria,
        StripedDataGrid,
        searchFormState,
        navigate
    };
} 

export default useSearch;