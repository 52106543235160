 
export const UserProfileLocalStorage = "UserProfileLocalStorage";

export const SignedOutSessionStorage = "SignedOutSessionStorage";

export const SignedOutRequestStatus = "SignedOut";

export const DateFormat = 'MM/dd/yyyy';

export const EmailExpValidator = /^[A-Z0-9._%+-]+@[A-Z0-9]([A-Z0-9.-]*[A-Z0-9])*\.[A-Z]{2,}$/i; 

export const Countries=[
    {value:'Afghanistan',name:'Afghanistan'},
    {value:'Albania',name:'Albania'},
    {value:'Algeria',name:'Algeria'},
    {value:'Andorra',name:'Andorra'},
    {value:'Angola',name:'Angola'},
    {value:'Antigua and Barbuda',name:'Antigua and Barbuda'},
    {value:'Argentina',name:'Argentina'},
    {value:'Armenia',name:'Armenia'},
    {value:'Australia',name:'Australia'},
    {value:'Austria',name:'Austria'},
    {value:'Austrian Empire*',name:'Austrian Empire*'},
    {value:'Azerbaijan',name:'Azerbaijan'},
    {value:'Baden*',name:'Baden*'},
    {value:'Bahamas, The',name:'Bahamas, The'},
    {value:'Bahrain',name:'Bahrain'},
    {value:'Bangladesh',name:'Bangladesh'},
    {value:'Barbados',name:'Barbados'},
    {value:'Bavaria*',name:'Bavaria*'},
    {value:'Belarus',name:'Belarus'},
    {value:'Belgium',name:'Belgium'},
    {value:'Belize',name:'Belize'},
    {value:'Benin (Dahomey)',name:'Benin (Dahomey)'},
    {value:'Bolivia',name:'Bolivia'},
    {value:'Bosnia and Herzegovina',name:'Bosnia and Herzegovina'},
    {value:'Botswana',name:'Botswana'},
    {value:'Brazil',name:'Brazil'},
    {value:'Brunei',name:'Brunei'},
    {value:'Brunswick and Lüneburg',name:'Brunswick and Lüneburg'},
    {value:'Bulgaria',name:'Bulgaria'},
    {value:'Burkina Faso (Upper Volta)',name:'Burkina Faso (Upper Volta)'},
    {value:'Burma',name:'Burma'},
    {value:'Burundi',name:'Burundi'},
    {value:'Cabo Verde',name:'Cabo Verde'},
    {value:'Cambodia',name:'Cambodia'},
    {value:'Cameroon',name:'Cameroon'},
    {value:'Canada',name:'Canada'},
    {value:'Cayman Islands, The',name:'Cayman Islands, The'},
    {value:'Central African Republic',name:'Central African Republic'},
    {value:'Central American Federation*',name:'Central American Federation*'},
    {value:'Chad',name:'Chad'},
    {value:'Chile',name:'Chile'},
    {value:'China',name:'China'},
    {value:'Colombia',name:'Colombia'},
    {value:'Comoros',name:'Comoros'},
    {value:'Congo Free State, The*',name:'Congo Free State, The*'},
    {value:'Costa Rica',name:'Costa Rica'},
    {value:'Cote d’Ivoire (Ivory Coast)',name:'Cote d’Ivoire (Ivory Coast)'},
    {value:'Croatia',name:'Croatia'},
    {value:'Cuba',name:'Cuba'},
    {value:'Cyprus',name:'Cyprus'},
    {value:'Czechia',name:'Czechia'},
    {value:'Czechoslovakia',name:'Czechoslovakia'},
    {value:'Democratic Republic of the Congo',name:'Democratic Republic of the Congo'},
    {value:'Denmark',name:'Denmark'},
    {value:'Djibouti',name:'Djibouti'},
    {value:'Dominica',name:'Dominica'},
    {value:'Dominican Republic',name:'Dominican Republic'},
    {value:'Duchy of Parma, The*',name:'Duchy of Parma, The*'},
    {value:'East Germany (German Democratic Republic)*',name:'East Germany (German Democratic Republic)*'},
    {value:'Ecuador',name:'Ecuador'},
    {value:'Egypt',name:'Egypt'},
    {value:'El Salvador',name:'El Salvador'},
    {value:'Equatorial Guinea',name:'Equatorial Guinea'},
    {value:'Eritrea',name:'Eritrea'},
    {value:'Estonia',name:'Estonia'},
    {value:'Eswatini',name:'Eswatini'},
    {value:'Ethiopia',name:'Ethiopia'},
    {value:'Federal Government of Germany (1848-49)*',name:'Federal Government of Germany (1848-49)*'},
    {value:'Fiji',name:'Fiji'},
    {value:'Finland',name:'Finland'},
    {value:'France',name:'France'},
    {value:'Gabon',name:'Gabon'},
    {value:'Gambia, The',name:'Gambia, The'},
    {value:'Georgia',name:'Georgia'},
    {value:'Germany',name:'Germany'},
    {value:'Ghana',name:'Ghana'},
    {value:'Grand Duchy of Tuscany, The*',name:'Grand Duchy of Tuscany, The*'},
    {value:'Greece',name:'Greece'},
    {value:'Grenada',name:'Grenada'},
    {value:'Guatemala',name:'Guatemala'},
    {value:'Guinea',name:'Guinea'},
    {value:'Guinea-Bissau',name:'Guinea-Bissau'},
    {value:'Guyana',name:'Guyana'},
    {value:'Haiti',name:'Haiti'},
    {value:'Hanover*',name:'Hanover*'},
    {value:'Hanseatic Republics*',name:'Hanseatic Republics*'},
    {value:'Hawaii*',name:'Hawaii*'},
    {value:'Hesse*',name:'Hesse*'},
    {value:'Holy See',name:'Holy See'},
    {value:'Honduras',name:'Honduras'},
    {value:'Hungary',name:'Hungary'},
    {value:'Iceland',name:'Iceland'},
    {value:'India',name:'India'},
    {value:'Indonesia',name:'Indonesia'},
    {value:'Iran',name:'Iran'},
    {value:'Iraq',name:'Iraq'},
    {value:'Ireland',name:'Ireland'},
    {value:'Israel',name:'Israel'},
    {value:'Italy',name:'Italy'},
    {value:'Jamaica',name:'Jamaica'},
    {value:'Japan',name:'Japan'},
    {value:'Jordan',name:'Jordan'},
    {value:'Kazakhstan',name:'Kazakhstan'},
    {value:'Kenya',name:'Kenya'},
    {value:'Kingdom of Serbia/Yugoslavia*',name:'Kingdom of Serbia/Yugoslavia*'},
    {value:'Kiribati',name:'Kiribati'},
    {value:'Korea',name:'Korea'},
    {value:'Kosovo',name:'Kosovo'},
    {value:'Kuwait',name:'Kuwait'},
    {value:'Kyrgyzstan',name:'Kyrgyzstan'},
    {value:'Laos',name:'Laos'},
    {value:'Latvia',name:'Latvia'},
    {value:'Lebanon',name:'Lebanon'},
    {value:'Lesotho',name:'Lesotho'},
    {value:'Lew Chew (Loochoo)*',name:'Lew Chew (Loochoo)*'},
    {value:'Liberia',name:'Liberia'},
    {value:'Libya',name:'Libya'},
    {value:'Liechtenstein',name:'Liechtenstein'},
    {value:'Lithuania',name:'Lithuania'},
    {value:'Luxembourg',name:'Luxembourg'},
    {value:'Madagascar',name:'Madagascar'},
    {value:'Malawi',name:'Malawi'},
    {value:'Malaysia',name:'Malaysia'},
    {value:'Maldives',name:'Maldives'},
    {value:'Mali',name:'Mali'},
    {value:'Malta',name:'Malta'},
    {value:'Marshall Islands',name:'Marshall Islands'},
    {value:'Mauritania',name:'Mauritania'},
    {value:'Mauritius',name:'Mauritius'},
    {value:'Mecklenburg-Schwerin*',name:'Mecklenburg-Schwerin*'},
    {value:'Mecklenburg-Strelitz*',name:'Mecklenburg-Strelitz*'},
    {value:'Mexico',name:'Mexico'},
    {value:'Micronesia',name:'Micronesia'},
    {value:'Moldova',name:'Moldova'},
    {value:'Monaco',name:'Monaco'},
    {value:'Mongolia',name:'Mongolia'},
    {value:'Montenegro',name:'Montenegro'},
    {value:'Morocco',name:'Morocco'},
    {value:'Mozambique',name:'Mozambique'},
    {value:'Namibia',name:'Namibia'},
    {value:'Nassau*',name:'Nassau*'},
    {value:'Nauru',name:'Nauru'},
    {value:'Nepal',name:'Nepal'},
    {value:'Netherlands, The',name:'Netherlands, The'},
    {value:'New Zealand',name:'New Zealand'},
    {value:'Nicaragua',name:'Nicaragua'},
    {value:'Niger',name:'Niger'},
    {value:'Nigeria',name:'Nigeria'},
    {value:'North German Confederation*',name:'North German Confederation*'},
    {value:'North German Union*',name:'North German Union*'},
    {value:'North Macedonia',name:'North Macedonia'},
    {value:'Norway',name:'Norway'},
    {value:'Oldenburg*',name:'Oldenburg*'},
    {value:'Oman',name:'Oman'},
    {value:'Orange Free State*',name:'Orange Free State*'},
    {value:'Pakistan',name:'Pakistan'},
    {value:'Palau',name:'Palau'},
    {value:'Panama',name:'Panama'},
    {value:'Papal States*',name:'Papal States*'},
    {value:'Papua New Guinea',name:'Papua New Guinea'},
    {value:'Paraguay',name:'Paraguay'},
    {value:'Peru',name:'Peru'},
    {value:'Philippines',name:'Philippines'},
    {value:'Piedmont-Sardinia*',name:'Piedmont-Sardinia*'},
    {value:'Poland',name:'Poland'},
    {value:'Portugal',name:'Portugal'},
    {value:'Qatar',name:'Qatar'},
    {value:'Republic of Genoa*',name:'Republic of Genoa*'},
    {value:'Republic of Korea (South Korea)',name:'Republic of Korea (South Korea)'},
    {value:'Republic of the Congo',name:'Republic of the Congo'},
    {value:'Romania',name:'Romania'},
    {value:'Russia',name:'Russia'},
    {value:'Rwanda',name:'Rwanda'},
    {value:'Saint Kitts and Nevis',name:'Saint Kitts and Nevis'},
    {value:'Saint Lucia',name:'Saint Lucia'},
    {value:'Saint Vincent and the Grenadines',name:'Saint Vincent and the Grenadines'},
    {value:'Samoa',name:'Samoa'},
    {value:'San Marino',name:'San Marino'},
    {value:'Sao Tome and Principe',name:'Sao Tome and Principe'},
    {value:'Saudi Arabia',name:'Saudi Arabia'},
    {value:'Schaumburg-Lippe*',name:'Schaumburg-Lippe*'},
    {value:'Senegal',name:'Senegal'},
    {value:'Serbia',name:'Serbia'},
    {value:'Seychelles',name:'Seychelles'},
    {value:'Sierra Leone',name:'Sierra Leone'},
    {value:'Singapore',name:'Singapore'},
    {value:'Slovakia',name:'Slovakia'},
    {value:'Slovenia',name:'Slovenia'},
    {value:'Solomon Islands, The',name:'Solomon Islands, The'},
    {value:'Somalia',name:'Somalia'},
    {value:'South Africa',name:'South Africa'},
    {value:'South Sudan',name:'South Sudan'},
    {value:'Spain',name:'Spain'},
    {value:'Sri Lanka',name:'Sri Lanka'},
    {value:'Sudan',name:'Sudan'},
    {value:'Suriname',name:'Suriname'},
    {value:'Sweden',name:'Sweden'},
    {value:'Switzerland',name:'Switzerland'},
    {value:'Syria',name:'Syria'},
    {value:'Tajikistan',name:'Tajikistan'},
    {value:'Tanzania',name:'Tanzania'},
    {value:'Texas*',name:'Texas*'},
    {value:'Thailand',name:'Thailand'},
    {value:'Timor-Leste',name:'Timor-Leste'},
    {value:'Togo',name:'Togo'},
    {value:'Tonga',name:'Tonga'},
    {value:'Trinidad and Tobago',name:'Trinidad and Tobago'},
    {value:'Tunisia',name:'Tunisia'},
    {value:'Turkey',name:'Turkey'},
    {value:'Turkmenistan',name:'Turkmenistan'},
    {value:'Tuvalu',name:'Tuvalu'},
    {value:'Two Sicilies*',name:'Two Sicilies*'},
    {value:'Uganda',name:'Uganda'},
    {value:'Ukraine',name:'Ukraine'},
    {value:'Union of Soviet Socialist Republics*',name:'Union of Soviet Socialist Republics*'},
    {value:'United Arab Emirates, The',name:'United Arab Emirates, The'},
    {value:'United Kingdom, The',name:'United Kingdom, The'},
    {value:'Uruguay',name:'Uruguay'},
    {value:'Uzbekistan',name:'Uzbekistan'},
    {value:'Vanuatu',name:'Vanuatu'},
    {value:'Venezuela',name:'Venezuela'},
    {value:'Vietnam',name:'Vietnam'},
    {value:'Württemberg*',name:'Württemberg*'},
    {value:'Yemen',name:'Yemen'},
    {value:'Zambia',name:'Zambia'},
    {value:'Zimbabwe',name:'Zimbabwe'},
];
    
export const Provinces=[ 
    {value:'Alberta',name:'Alberta'},
    {value:'British Columbia',name:'British Columbia'},
    {value:'Manitoba',name:'Manitoba'},
    {value:'New Brunswick',name:'New Brunswick'},
    {value:'Newfoundland and Labrador',name:'Newfoundland and Labrador'},
    {value:'Northwest Territories',name:'Northwest Territories'},
    {value:'Nova Scotia',name:'Nova Scotia'},
    {value:'Nunavut',name:'Nunavut'},
    {value:'Ontario',name:'Ontario'},
    {value:'Prince Edward Island',name:'Prince Edward Island'},
    {value:'Quebec',name:'Quebec'},
    {value:'Saskatchewan',name:'Saskatchewan'},
    {value:'Yukon Territory',name:'Yukon Territory'}, 
];

